import Fuse from 'fuse.js';

import { PartyChatApp } from './party_details_app.jsx';
import { PartyDetailsContentApp, PartyDetailsContentAppV2} from './party_details_app.jsx';
import Notifications from './notifications_app.jsx';
import NextButton from './next_button_app.jsx';
import { ItemAttrsEditorApp } from './item_attrs_editor.jsx';
import AddInventoryApp from './add_inventory_app.jsx';
import AttrNamePickerApp from './attr_name_picker.jsx';
import QRLabelPickerApp from './QRLabelPicker.jsx';
import RewardsConsultantsApp from './rewards/rewards_consultants_app.jsx';
import RewardsSalesApp from './rewards/rewards_sales_app.jsx';
import RewardsShoppersApp from './rewards/rewards_shoppers_app.jsx';
import InvItemEditorApp from './invitem_editor_app.jsx';
import BillingHistoryApp from './billing_history_app.jsx';
import { AddItemChoiceApp } from './AddItemChoice.jsx';
import { AddSizeChoiceApp } from './AddSizeChoice.jsx';
import StyleSizeManagerApp from './stylesize_manager_app.jsx';
import ShippingLabelsApp from './shipping_labels_app.jsx';
import { StrBucksApp } from './StrBucks.jsx';
import UserProfileApp from './social/user_profile_app.jsx';
import SocialFeedApp from './social/social_feed_app.jsx';
import SocialFeedCardApp from './social/social_feed_card_app.jsx';
import SocialPartyFeedApp from './social/social_party_feed_app.jsx';
import SocialTimelineApp from './social/SocialTimelineApp.jsx';
import MergeOrdersApp from './merge_orders_app.jsx';
import SocialSuggestionsApp from './social/social_suggestions_app.jsx';
import { MiniProfileApp } from './social/MiniProfile.jsx';
import { RelatedItemsApp } from './RelatedItems.jsx';
import { ItemDetailsApp } from './item_details_app.jsx';
import ClaimButtonApp from './claim_button_app.jsx';
import OrderPickerApp from './order_picker_app.jsx';
import { BillingInfoApp } from './BillingInfo.jsx';
import { SmsUsageInfoApp } from './SmsUsageInfo.jsx';
import NavCartApp from './NavCart.jsx';
import toast from './toastie_app.jsx';
import InvoiceApp from './invoice_app.jsx';
import AchSetupApp from './ach_setup_app.jsx';
import UploadBlessCSVApp from './upload_bless_csv_app.jsx';
import InvoiceRefundApp from './invoice_refund_app.jsx';
import StrTableUtils from './str_table_utils.js';
import ShippingCostSetupApp from './shipping_cost_setup_app.jsx';
import { SocialPostEntryApp } from './social_post_app.jsx';
import InvoiceSettingsApp from './invoice_settings_app.jsx';
import PartyAnnouncementApp from './party_announcement_app.jsx';
import NexusStateSetupApp from './nexus_states_setup_app.jsx';
import { debounce } from 'lodash-es';
import { formatCurrency } from './util.jsx';
import AttributeSettingsApp from './attributes_settings_app.jsx';
import MultiPartyDirectoryAdderApp from './MultiPartyDirectoryAdder.jsx';
import PartyJoinRulesApp from './party_join_rules_app.jsx';
import InPartyStatsApp from './in_party_stats_app.jsx';
import SmartAddApp from './smart_add_app.jsx';
import { PartyAlbumTargeterApp } from './PartyAlbumTargeter.jsx';
import StrPartyItemsApp from './str_partyitems_app.jsx';
import SwiftOrderImportApp from './swiftorder_import_app.jsx';
import StrPartyShuffleStatusApp from './str_partyshufflestatus_app.jsx';
import RecentSalesTrendsApp from './RecentSalesTrends.jsx';
import InventoryLevelAlertsApp from './InventoryLevelAlerts.jsx';
import ListingsTemplates from './ListingsTemplates.jsx';
import ListingsFiltersApp from './listings_filters_app.jsx';
import SalesFiltersApp from './sales_filters_app.jsx';
import MessagesApp from './messages_app.jsx';
import SendDirectMessageApp from './SendDirectMessage.jsx';
import AccountSetupApp from './account_setup_app.jsx';
import AccountOnboardingJourneyApp from './account_onboarding_journey_app.jsx';
import InventoryOverviewTableApp from './InventoryOverviewTable.jsx';
import OmniPartyApp from './omniparty_app.jsx';
import { LikeButtonApp } from './LikeButton.jsx';
import { DigitalDownloadsEditorApp } from './DigitalDownloadsEditor.jsx';
import { MarketItemFormApp } from './MarketItemForm.jsx';
import { ConfettiApp } from './Confetti.jsx';
import { ProfilePopoverApp } from './social/ProfilePopover.jsx';
import MarketFeatureButtonApp from './MarketFeatureButton.jsx';
import SalesTemplates from './SalesTemplates.jsx';
import PendingSalesTableApp from './pending_sales_table_app.jsx';
import strWebsocket from './strWebsocket.js';
import CopyAlbumSettingsApp from './copy_album_settings_app.jsx';
import { ShippingReturnLabelApp } from './ShippingQuotesV2.jsx';
import {
    SonletCatalogTabsApp,
    SonletCatalogDropShipItemsApp,
    SonletCatalogPromotedItemsApp,
} from './sonlet_catalog.jsx';
import InvItemImageUploadApp from './invitem_image_upload_app.jsx';
import BackToTopButtonApp from './BackToTopButton.jsx';
import { PartyTaggerApp } from './PartyTagger.jsx';
import { PartyPickerV2App } from './PartyPickerV2.jsx';
import { StaffPartyPickerApp } from './StaffPartyPicker.jsx';
import SUPFbPickCanvasApp from './SUPFbPickCanvas.jsx';
import CollagesApp from './collages/CollagesApp.jsx';
import StickersAdminApp from './stickers/StickersAdminApp.jsx';

export default {
    PartyChatApp: PartyChatApp,
    PartyDetailsContentAppV2: PartyDetailsContentAppV2,
    Notifications: Notifications,
    NextButton: NextButton,
    Fuse: Fuse,
    ItemAttrsEditorApp: ItemAttrsEditorApp,
    AddInventoryApp: AddInventoryApp,
    AttrNamePickerApp: AttrNamePickerApp,
    QRLabelPickerApp: QRLabelPickerApp,
    RewardsConsultantsApp: RewardsConsultantsApp,
    RewardsSalesApp: RewardsSalesApp,
    RewardsShoppersApp: RewardsShoppersApp,
    InvItemEditorApp: InvItemEditorApp,
    BillingHistoryApp: BillingHistoryApp,
    AddItemChoiceApp: AddItemChoiceApp,
    AddSizeChoiceApp: AddSizeChoiceApp,
    StyleSizeManagerApp: StyleSizeManagerApp,
    ShippingLabelsApp: ShippingLabelsApp,
    StrBucksApp: StrBucksApp,
    UserProfileApp: UserProfileApp,
    SocialFeedApp: SocialFeedApp,
    SocialFeedCardApp: SocialFeedCardApp,
    SocialPartyFeedApp: SocialPartyFeedApp,
    MergeOrdersApp: MergeOrdersApp,
    SocialSuggestionsApp: SocialSuggestionsApp,
    MiniProfileApp: MiniProfileApp,
    RelatedItemsApp: RelatedItemsApp,
    ItemDetailsApp: ItemDetailsApp,
    ClaimButtonApp: ClaimButtonApp,
    OrderPickerApp: OrderPickerApp,
    BillingInfoApp: BillingInfoApp,
    SmsUsageInfoApp: SmsUsageInfoApp,
    NavCartApp: NavCartApp,
    toast: toast,
    InvoiceApp: InvoiceApp,
    AchSetupApp: AchSetupApp,
    UploadBlessCSVApp: UploadBlessCSVApp,
    SocialTimelineApp: SocialTimelineApp,
    InvoiceRefundApp: InvoiceRefundApp,
    StrTableUtils: StrTableUtils,
    ShippingCostSetupApp: ShippingCostSetupApp,
    SocialPostEntryApp: SocialPostEntryApp,
    InvoiceSettingsApp: InvoiceSettingsApp,
    PartyAnnouncementApp: PartyAnnouncementApp,
    NexusStateSetupApp: NexusStateSetupApp,
    debounce: debounce,
    formatCurrency: formatCurrency,
    AttributeSettingsApp: AttributeSettingsApp,
    MultiPartyDirectoryAdderApp: MultiPartyDirectoryAdderApp,
    PartyJoinRulesApp: PartyJoinRulesApp,
    InPartyStatsApp: InPartyStatsApp,
    SmartAddApp: SmartAddApp,
    PartyAlbumTargeterApp: PartyAlbumTargeterApp,
    StrPartyItemsApp: StrPartyItemsApp,
    SwiftOrderImportApp: SwiftOrderImportApp,
    StrPartyShuffleStatusApp: StrPartyShuffleStatusApp,
    RecentSalesTrendsApp: RecentSalesTrendsApp,
    InventoryLevelAlertsApp: InventoryLevelAlertsApp,
    ListingsTemplates: ListingsTemplates,
    ListingsFiltersApp: ListingsFiltersApp,
    SalesFiltersApp: SalesFiltersApp,
    MessagesApp: MessagesApp,
    SendDirectMessageApp: SendDirectMessageApp,
    AccountSetupApp: AccountSetupApp,
    AccountOnboardingJourneyApp: AccountOnboardingJourneyApp,
    InventoryOverviewTableApp: InventoryOverviewTableApp,
    OmniPartyApp: OmniPartyApp,
    LikeButtonApp: LikeButtonApp,
    DigitalDownloadsEditorApp: DigitalDownloadsEditorApp,
    MarketItemFormApp: MarketItemFormApp,
    ConfettiApp: ConfettiApp,
    ProfilePopoverApp: ProfilePopoverApp,
    MarketFeatureButtonApp: MarketFeatureButtonApp,
    SalesTemplates: SalesTemplates,
    PendingSalesTableApp: PendingSalesTableApp,
    strWebsocket: strWebsocket,
    CopyAlbumSettingsApp: CopyAlbumSettingsApp,
    ShippingReturnLabelApp: ShippingReturnLabelApp,
    SonletCatalogTabsApp: SonletCatalogTabsApp,
    SonletCatalogPromotedItemsApp: SonletCatalogPromotedItemsApp,
    SonletCatalogDropShipItemsApp: SonletCatalogDropShipItemsApp,
    InvItemImageUploadApp: InvItemImageUploadApp,
    BackToTopButtonApp: BackToTopButtonApp,
    PartyTaggerApp: PartyTaggerApp,
    PartyPickerV2App: PartyPickerV2App,
    StaffPartyPickerApp: StaffPartyPickerApp,
    SUPFbPickCanvasApp: SUPFbPickCanvasApp,
    CollagesApp: CollagesApp,
    StickersAdminApp: StickersAdminApp,
};
