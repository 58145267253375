import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

import { ItemAttrsEditor } from './item_attrs_editor.jsx';
import ImageUploadOrSelectV2 from './ImageUploadOrSelectV2.jsx';
import InvItemEditor from './InvItemEditor.jsx';
import { AddItemChoice } from './AddItemChoice.jsx';
import { AddSizeChoice } from './AddSizeChoice.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import { StrDayPicker } from './StrDates.jsx';
import { getUUID, styleSizeCustomLabel, fetchJSON, strRequestJSON } from './util.jsx';

const defaultSettings = {
    quantity: 1,
    wholesalePrice: "",
    price: "",
    description: "",
    purchaseDate: moment(),
    attrs: [],
    itemchoice: null,
    size: null,
    createAsListed: true,
};

const loadStyleOptions = async () => {
    const json = await fetchJSON('/api/v2/itemchoices/');
    return json.results.map(el => ({
        label: styleSizeCustomLabel(el),
        value: el.pk,
    }));
};

const loadSizeOptions = async () => {
    const json = await fetchJSON('/api/v2/sizes/');
    return json.results
               .sort((a, b) => Number.parseInt(a.sort_order) - Number.parseInt(b.sort_order))
               .map(el => ({
                   label: styleSizeCustomLabel(el),
                   value: el.pk,
               }));
};

// Component to show batch header with summary of settings
const BatchHeader = ({ settings, onToggle, isCollapsed }) => {
    const badges = [];

    if (settings.itemchoice?.label) {
        badges.push(
            <span key="style" className="badge badge-style">
              {StrUserInfo.words.style}: {settings.itemchoice.label}
            </span>
        );
    }

    if (settings.size?.label) {
        badges.push(
            <span key="size" className="badge badge-size">
              {StrUserInfo.words.size}: {settings.size.label}
            </span>
        );
    }

    if (settings.wholesalePrice || settings.price) {
        badges.push(
            <span key="price" className="badge badge-price">
              ${settings.wholesalePrice}/${settings.price}
            </span>
        );
    }

    if (settings.attrs.length > 0) {
        badges.push(
            <span key="attrs" className="badge badge-attrs">
              {settings.attrs.map(a => `${a.name}: ${a.value}`).join(', ')}
            </span>
        );
    }

    return (
        <div className="str-bulk-header" onClick={onToggle}>
          <i className={`fa fa-chevron-${isCollapsed ? 'right' : 'down'}`} />
          <span className="str-bulk-summary">
            {badges.length > 0 ? badges : 'No settings applied'}
          </span>
        </div>
    );
};

BatchHeader.propTypes = {
    settings: PropTypes.shape({
        itemchoice: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
        size: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
        attrs: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
        })),
        wholesalePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    onToggle: PropTypes.func.isRequired,
    isCollapsed: PropTypes.bool.isRequired,
};

const SaveAllButton = ({ disabled, isComplete, onSave }) => {
    const spinning = !isComplete && disabled;
    return (
        <div className="row" style={{marginBottom: "10px"}}>
          <div className="col-md-12">
            <button
                type="button"
                className={`btn btn-primary btn-lg${disabled ? ' disabled' : ''}`}
                onClick={onSave}>
              <i className="fa fa-floppy-o"></i> Save all
            </button>
            {spinning && <div className="help-text text-small"> Saving... </div>}
            {spinning && <LoadingSpinner />}
          </div>
        </div>
    );
};

SaveAllButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
};

const ItemForm = ({ settings, onChange }) => {
    const onAttrChange = useCallback((attrs) => {
        onChange('attrs', attrs);

        // Handle price suggestions if not in str mode
        if (!is_str) {
            const data = encodeURIComponent(JSON.stringify(attrs));
            fetchJSON(`/api/v2/stockitems/pricing/?attrs=${data}`)
                .then(json => {
                    if (json?.length > 0) {
                        onChange('wholesalePrice', json[0].wholesale_price);
                        onChange('price', json[0].suggested_retail_price);
                    }
                });
        }
    }, [onChange]);

    const onStyleChange = useCallback((itemchoice) => {
        onChange('itemchoice', itemchoice);

        if (itemchoice) {  // Only fetch prices if we have a valid choice
            fetchJSON(`/itemchoice_params/${itemchoice.value}/`)
            .then(data => {
                onChange('wholesalePrice', data.wholesale_price + '');
                onChange('price', data.suggested_retail_price_top + '');
            });
        }
    }, [onChange]);

    const bizParams = (typeof str_biz_params !== 'undefined')
                    ? str_biz_params
                    : {'suggested_attrs': []};

    return (
        <div className="well well-sm">
          <div className="row">
            <div className="col-md-12">
              <p className="help-block">These settings can be changed on a per-item basis after upload.</p>
              <div>
                <div style={{marginBottom: '10px'}}>
                  <CreatableSelect
                      name="itemchoice_id"
                      value={settings.itemchoice}
                      onChange={onStyleChange}
                      placeholder={StrUserInfo.words.style}
                      options={settings.styleOptions}
                      onCreateOption={settings.onCreateItemChoice}
                      isClearable
                  />
                  <AddItemChoice
                      onAdd={settings.onItemChoiceAdd}
                      initialName={settings.itemChoiceInputValue}
                      showModal={settings.showItemChoiceModal}
                      onClose={settings.onCloseItemChoice}
                  />
                </div>
                <div>
                  <CreatableSelect
                      name="size_id"
                      value={settings.size}
                      onChange={(size) => onChange('size', size)}
                      placeholder={StrUserInfo.words.size}
                      options={settings.sizeOptions}
                      onCreateOption={settings.onCreateSizeChoice}
                      isClearable
                  />
                  <AddSizeChoice
                      onAdd={settings.onSizeChoiceAdd}
                      initialName={settings.sizeInputValue}
                      showModal={settings.showSizeChoiceModal}
                      onClose={settings.onCloseSizeChoice}
                  />
                </div>
              </div>
              <div>
                <p className="help-block">Attributes</p>
                <ItemAttrsEditor
                    bizParams={bizParams}
                    defaultAttrs={settings.attrs}
                    receivedAttrs={onAttrChange}
                    quickAdds={strDefaultQuickAdds}
                    liteStyle={false}
                />
              </div>
            </div>
            <div className="col-md-12 form-horizontal">
              <div className="form-group">
                <label className="col-sm-4 control-label">Quantity</label>
                <div className="col-sm-5">
                  <input
                      type="number"
                      className="form-control"
                      min="1"
                      name="quantity"
                      value={settings.quantity}
                      onChange={e => onChange('quantity', Number(e.target.value))}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">Wholesale price</label>
                <div className="col-sm-5">
                  <input
                      type="number"
                      className="form-control"
                      min="0"
                      step="0.01"
                      name="wholesalePrice"
                      value={settings.wholesalePrice}
                      onChange={e => onChange('wholesalePrice', e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">Price</label>
                <div className="col-sm-5">
                  <input
                      type="number"
                      className="form-control"
                      min="0"
                      step="0.01"
                      name="price"
                      value={settings.price}
                      onChange={e => onChange('price', e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">Description</label>
                <div className="col-sm-8">
                  <textarea
                      className="form-control"
                      rows="3"
                      name="description"
                      value={settings.description}
                      onChange={e => onChange('description', e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">Purchase date</label>
                <div className="col-sm-8" style={{paddingTop: "6px"}}>
                  <StrDayPicker
                      mode="single"
                      selected={settings.purchaseDate.toDate()}
                      onSelect={d => onChange('purchaseDate', moment(d))}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">Auto-list</label>
                <div className="col-sm-8" style={{paddingTop: '7px'}}>
                  <label className="text-muted text-small" style={{fontWeight: 'normal'}}>
                    <input
                        type="checkbox"
                        name="createAsListed"
                        checked={settings.createAsListed}
                        onChange={e => onChange('createAsListed', e.target.checked)}
                    />
                    {' '}
                    Make these items available for sale right away.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

ItemForm.propTypes = {
    settings: PropTypes.shape({
        quantity: PropTypes.number,
        wholesalePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        description: PropTypes.string,
        purchaseDate: PropTypes.object, // moment object
        attrs: PropTypes.array,
        itemchoice: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
        size: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
        createAsListed: PropTypes.bool,
        styleOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        })),
        sizeOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        })),
        onCreateItemChoice: PropTypes.func.isRequired,
        onCreateSizeChoice: PropTypes.func.isRequired,
        onItemChoiceAdd: PropTypes.func.isRequired,
        onSizeChoiceAdd: PropTypes.func.isRequired,
        itemChoiceInputValue: PropTypes.string,
        sizeInputValue: PropTypes.string,
        showItemChoiceModal: PropTypes.bool,
        showSizeChoiceModal: PropTypes.bool,
        onCloseItemChoice: PropTypes.func.isRequired,
        onCloseSizeChoice: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};

// Add this helper function at the top level
const createBatchFromSettings = (settings) => ({
    id: getUUID(),
    settings: { ...settings },
    items: [],
    isCollapsed: false,
    savedItems: new Set(),
});

// Add this helper function
const getSettingsKey = (settings) => {
    return JSON.stringify({
        itemchoice: settings.itemchoice?.value,
        size: settings.size?.value,
        wholesalePrice: settings.wholesalePrice,
        price: settings.price,
        attrs: settings.attrs,
    });
};

export const BulkAddV2 = ({
    uploaderOnly = false,
    noEditorsClearfix = false,
    editorsClassName = "col-md-4",
    onImage = null,
}) => {
    const [batches, setBatches] = useState([]); // Each batch has settings and items
    const [currentSettings, setCurrentSettings] = useState(defaultSettings);
    const [styleOptions, setStyleOptions] = useState([]);
    const [sizeOptions, setSizeOptions] = useState([]);
    const [saving, setSaving] = useState(false);
    const [saveComplete, setSaveComplete] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);

    // Add state for choice modals
    const [showItemChoiceModal, setShowItemChoiceModal] = useState(false);
    const [itemChoiceInputValue, setItemChoiceInputValue] = useState('');
    const [showSizeChoiceModal, setShowSizeChoiceModal] = useState(false);
    const [sizeInputValue, setSizeInputValue] = useState('');

    // Load options on mount
    useEffect(() => {
        const loadOptions = async () => {
            const [styles, sizes] = await Promise.all([
                loadStyleOptions(),
                loadSizeOptions(),
            ]);
            setStyleOptions(styles);
            setSizeOptions(sizes);
        };
        loadOptions();
    }, []);

    // Handle creating new choices
    const onCreateItemChoice = useCallback((inputValue) => {
        setItemChoiceInputValue(inputValue);
        setShowItemChoiceModal(true);
    }, []);

    const onCreateSizeChoice = useCallback((inputValue) => {
        setSizeInputValue(inputValue);
        setShowSizeChoiceModal(true);
    }, []);

    const onItemChoiceAdd = useCallback(async (data) => {
        const newStyle = {
            label: data.name,
            value: data.pk,
        };
        setStyleOptions(prev => [...prev, newStyle]);
        setShowItemChoiceModal(false);

        // Update current settings with new style
        setCurrentSettings(prev => ({
            ...prev,
            itemchoice: newStyle,
        }));

        // Get and set suggested prices
        const priceData = await fetchJSON(`/itemchoice_params/${data.pk}/`);
        if (priceData) {
            setCurrentSettings(prev => ({
                ...prev,
                wholesalePrice: priceData.wholesale_price + '',
                price: priceData.suggested_retail_price_top + '',
            }));
        }
    }, []);

    const onSizeChoiceAdd = useCallback((data) => {
        const newSize = {
            label: data.name,
            value: data.pk,
        };
        setSizeOptions(prev => [...prev, newSize]);
        setShowSizeChoiceModal(false);

        // Update current settings with new size
        setCurrentSettings(prev => ({
            ...prev,
            size: newSize,
        }));
    }, []);

    const onCloseItemChoice = useCallback(() => {
        setShowItemChoiceModal(false);
        setItemChoiceInputValue('');
    }, []);

    const onCloseSizeChoice = useCallback(() => {
        setShowSizeChoiceModal(false);
        setSizeInputValue('');
    }, []);

    // Update ItemForm component to pass these handlers
    const itemForm = settingsVisible && (
        <ItemForm
            settings={{
                ...currentSettings,
                styleOptions,
                sizeOptions,
                onCreateItemChoice,
                onCreateSizeChoice,
                onItemChoiceAdd,
                onSizeChoiceAdd,
                itemChoiceInputValue,
                sizeInputValue,
                showItemChoiceModal,
                showSizeChoiceModal,
                onCloseItemChoice,
                onCloseSizeChoice,
            }}
            onChange={(field, value) => setCurrentSettings(prev => ({
                ...prev,
                [field]: value,
            }))}
        />
    );

    // Add this function to handle initial file drops
    const handleInitialDrop = useCallback((fileObj) => {
        const { file, uuid } = fileObj;
        console.log('handleInitialDrop - new file:', { file, uuid });
        const newImage = {
            file,
            uuid,
            progress: 0,
            uploadedImage: null,
            status: 'uploading',
        };

        setBatches(prevBatches => {
            const settingsKey = getSettingsKey(currentSettings);
            const existingBatch = prevBatches.find(b =>
                getSettingsKey(b.settings) === settingsKey
            );

            if (existingBatch) {
                return prevBatches.map(batch =>
                    batch.id === existingBatch.id
                    ? { ...batch, items: [...batch.items, newImage] }
                    : batch
                );
            }

            return [...prevBatches, {
                ...createBatchFromSettings(currentSettings),
                items: [newImage],
            }];
        });
    }, [currentSettings]);

    // Update handleProgress (no changes needed, it's correct)
    const handleProgress = useCallback(({ uuid, percent }) => {
        setBatches(prev => prev.map(batch => ({
            ...batch,
            items: batch.items.map(item =>
                item.uuid === uuid
                ? { ...item, progress: percent }
                : item
            ),
        })));
    }, []);

    // Update handleComplete to just update the existing item
    const handleComplete = useCallback(({ uuid, file, status }, originalImage) => {
        console.log('handleComplete - received:', { uuid, file, status, originalImage });

        // Format the image object to match what InvItemEditor expects
        const imageObj = {
            id: file.pk || file.id,
            url: file.image_medium || file.image_full,  // Use medium size for display URL
            full_url: file.image_full,
            image_full: file.image_full,
            image_medium: file.image_medium,
            image_thumbnail: file.image_thumbnail,
            pk: file.pk,
            ...file,  // Keep any other properties
        };

        console.log('handleComplete - formatted image:', imageObj);

        setBatches(prev => prev.map(batch => ({
            ...batch,
            items: batch.items.map(item => {
                if (item.uuid === uuid) {
                    const updatedItem = {
                        ...item,
                        status,
                        image: imageObj,
                        originalImage,
                        progress: 100,
                    };
                    console.log('handleComplete - updated item:', updatedItem);
                    return updatedItem;
                }
                return item;
            }),
        })));

        // Notify parent component if needed
        onImage && onImage({
            uuid,
            file,
            status,
            originalImage,
            image: imageObj,
            progress: 100,
        });
    }, [onImage]);

    const toggleBatch = useCallback((batchId) => {
        setBatches(prev => prev.map(batch =>
            batch.id === batchId
            ? { ...batch, isCollapsed: !batch.isCollapsed }
            : batch
        ));
    }, []);

    // Add a new useEffect to track save completion
    useEffect(() => {
        if (saving) {
            // Check if all items are saved
            const allSaved = batches.every(batch =>
                batch.items.every(item =>
                    batch.savedItems.has(item.uuid)
                )
            );

            if (allSaved) {
                setSaving(false);
                setSaveComplete(true);
            }
        }
    }, [saving, batches]);

    const handleSaveComplete = useCallback((batchId, itemUid) => {
        setBatches(prev => prev.map(batch =>
            batch.id === batchId
            ? { ...batch, savedItems: new Set([...batch.savedItems, itemUid]) }
            : batch
        ));
    }, []);

    const handleDeleteItem = useCallback((batchId, itemUid) => {
        setBatches(prev => prev.map(batch => {
            if (batch.id !== batchId) return batch;

            // Remove the item from the batch
            const newItems = batch.items.filter(item => item.uuid !== itemUid);

            // Also remove from savedItems if it was there
            const newSavedItems = new Set(batch.savedItems);
            newSavedItems.delete(itemUid);

            // If this was the last item in the batch, remove the batch
            if (newItems.length === 0) {
                return null;
            }

            return {
                ...batch,
                items: newItems,
                savedItems: newSavedItems,
            };
        }).filter(Boolean)); // Remove any null batches

        // Clean up the ref
        if (editorRefs.current[itemUid]) {
            delete editorRefs.current[itemUid];
        }
    }, []);

    // Keep track of editor refs
    const editorRefs = useRef({});

    const getUploadArea = () => {
        const uploader = (
            <div className="str-bulk-progress-group">
              {batches.map(batch => (
                  <div key={batch.id} className="str-bulk-progress-group">
                    <div className="str-bulk-progress-header">
                      <BatchHeader
                          settings={batch.settings}
                          onToggle={() => {}} // No collapse in progress view
                          isCollapsed={false}
                      />
                    </div>
                    {batch.items.map(item => (
                        <div key={item.uuid} className="str-bulk-progress-item"> {/* Use uuid */}
                          {item.file instanceof File ? (
                              <img
                                  src={URL.createObjectURL(item.file)}
                                  alt=""
                                  className="str-bulk-preview"
                              />
                          ) : (
                              <div className="str-bulk-preview">
                                <i className="fa fa-file-image-o" />
                              </div>
                          )}
                          <div className="str-bulk-progress-bar">
                            <div
                                className="str-bulk-progress-bar-inner"
                                style={{width: `${item.progress || 0}%`}}
                            />
                          </div>
                          <span className="str-bulk-progress-text">
                            {item.progress === 100 ? (
                                <i className="fa fa-check text-success" />
                            ) : (
                                item.progress ? `${item.progress}%` : 'Starting...'
                            )}
                          </span>
                        </div>
                    ))}
                  </div>
              ))}
              <ImageUploadOrSelectV2
                  inputName="bulkImage"
                  onProgress={handleProgress}
                  onComplete={handleComplete}
                  onInitialDrop={handleInitialDrop}
                  multiple={true}
                  invItem={true}
                  showThumbnails={false}
                  showUploadStatus={false}
              />
            </div>
        );

        if (uploaderOnly) return uploader;

        return (
            <div>
              <div className="row" style={{marginTop: "30px"}}>
                <div className="col-md-5">
                  <h4>Step 1: Choose default settings (optional)</h4>
                  <p className="help-block">
                    Set the default {StrUserInfo.words.style}, {StrUserInfo.words.size}, etc. that will be applied to each uploaded image.
                  </p>
                  <button
                      type="button"
                      className="btn btn-link btn-lg"
                      onClick={() => setSettingsVisible(prev => !prev)}>
                    Default settings (optional)
                  </button>
                  {settingsVisible && (
                      <div>
                        <div
                            className="btn btn-default"
                            onClick={() => setCurrentSettings(defaultSettings)}>
                          Clear
                        </div>
                        {itemForm}
                      </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <h4>Step 2: Upload images</h4>
                  {uploader}
                </div>
              </div>
            </div>
        );
    };

    // Render batches with editors
    const renderBatches = () => {
        const hasUnsavedItems = batches.some(batch =>
            batch.items.some(item => !batch.savedItems.has(item.uuid))
        );

        return (
            <div>
              {batches.length > 0 && (
                  <SaveAllButton
                      disabled={saving || !hasUnsavedItems}
                      isComplete={saveComplete}
                      onSave={handleSaveAll}
                  />
              )}

              {batches.map(batch => (
                  <div key={batch.id} className="batch-container">
                    <BatchHeader
                        settings={batch.settings}
                        onToggle={() => toggleBatch(batch.id)}
                        isCollapsed={batch.isCollapsed}
                    />

                    {!batch.isCollapsed && (
                        <div className="row">
                          {batch.items
                                .filter(item => item.status === 'complete')
                                .map(item => (
                                    <div key={item.uuid} className={editorsClassName}>
                                      <InvItemEditor
                                          ref={el => {
                                              if (el) {
                                                  console.log('Setting editor ref for item:', item);
                                                  editorRefs.current[item.uuid] = el;
                                              }
                                          }}
                                          id={item.uuid}
                                          image={item.image}
                                          originalImage={item.originalImage}
                                          defaultSettings={batch.settings}
                                          onSaveComplete={() => handleSaveComplete(batch.id, item.uuid)}
                                          onSaveError={() => setSaving(false)}
                                          onDeleteClick={() => handleDeleteItem(batch.id, item.uuid)}
                                      />
                                    </div>
                          ))}
                          {!noEditorsClearfix && batch.items.length % 3 === 0 && (
                              <div className="clearfix" />
                          )}
                        </div>
                    )}
                  </div>
              ))}

              {batches.length > 0 && (
                  <SaveAllButton
                      disabled={saving || !hasUnsavedItems}
                      isComplete={saveComplete}
                      onSave={handleSaveAll}
                  />
              )}
            </div>
        );
    };

    const handleSaveAll = useCallback(() => {
        setSaving(true);
        setSaveComplete(false);

        // Trigger save on all unsaved InvItemEditor components
        batches.forEach(batch => {
            batch.items.forEach(item => {
                if (!batch.savedItems.has(item.uuid)) {
                    // Access the editor ref and trigger save
                    const editorRef = editorRefs.current[item.uuid];
                    if (editorRef) {
                        editorRef.onSaveClick();
                    }
                }
            });
        });
    }, [batches]);

    return (
        <div>
          {getUploadArea()}
          {renderBatches()}
        </div>
    );
};

BulkAddV2.propTypes = {
    uploaderOnly: PropTypes.bool,
    noEditorsClearfix: PropTypes.bool,
    editorsClassName: PropTypes.string,
    onImage: PropTypes.func,
};
