import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { fetchJSON, strRequest } from '../util';

function StickersCollectionList() {
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newCollectionTitle, setNewCollectionTitle] = useState('');
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [stickers, setStickers] = useState([]);
    const [loadingStickers, setLoadingStickers] = useState(false);
    const [personalStickersCount, setPersonalStickersCount] = useState(0);
    const [targetCollectionIds, setTargetCollectionIds] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const fetchCollections = async () => {
        try {
            setLoading(true);
            const data = await fetchJSON('/api/v2/stickers/collections/');
            setCollections(data);

            // Also fetch personal stickers count
            const personalData = await fetchJSON('/api/v2/stickers/?page_size=1');
            setPersonalStickersCount(personalData.count || 0);

            setLoading(false);
        } catch (err) {
            setError('Failed to load collections');
            setLoading(false);
        }
    };

    const fetchStickersForCollection = async (collectionId) => {
        try {
            setLoadingStickers(true);
            // If collectionId is null, fetch personal stickers
            const url = collectionId
                      ? `/api/v2/stickers/?collection_id=${collectionId}`
                      : '/api/v2/stickers/';
            const data = await fetchJSON(url);
            setStickers(data.results);
            setLoadingStickers(false);
        } catch (err) {
            setError('Failed to load stickers for this collection');
            setLoadingStickers(false);
        }
    };

    const fetchAll = async () => {
        let stickersFuture;
        if (selectedCollection) {
            stickersFuture = fetchStickersForCollection(selectedCollection.pk);
        } else {
            stickersFuture = fetchStickersForCollection(null);
        }
        await Promise.all([
            fetchCollections(),
            stickersFuture,
        ]);
    };

    const addStickerToCollection = async (stickerId, collectionId) => {
        if (!collectionId) {
            setError('Please select a collection');
            return;
        }

        try {
            setError('');
            setSuccessMessage('');

            const response = await strRequest(`/api/v2/stickers/collections/${collectionId}/`, 'PATCH', {
                action: 'add',
                sticker_id: stickerId,
            });

            if (response.ok) {
                const collectionData = await response.json();
                const collectionName = collectionData.title || 'collection';
                setSuccessMessage(`Sticker added to "${collectionName}" successfully!`);

                // Clear the selection for this sticker only
                setTargetCollectionIds(prev => ({
                    ...prev,
                    [stickerId]: '',
                }));

                fetchAll();
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to add sticker to collection');
            }
        } catch (err) {
            setError('Failed to add sticker to collection');
        }
    };

    const handleCreateCollection = async (e) => {
        e.preventDefault();
        if (!newCollectionTitle.trim()) return;

        try {
            const response = await strRequest('/api/v2/stickers/collections/', 'POST', {
                title: newCollectionTitle,
            });

            if (response.ok) {
                const newCollection = await response.json();
                setCollections([...collections, newCollection]);
                setNewCollectionTitle('');
            } else {
                setError('Failed to create collection');
            }
        } catch (err) {
            setError('Failed to create collection');
        }
    };

    const handleToggleFeatured = async (collection) => {
        try {
            const response = await strRequest(`/api/v2/stickers/collections/${collection.pk}/`, 'PATCH', {
                action: 'set_featured',
                is_featured: !collection.is_featured,
            });

            if (response.ok) {
                const updatedCollection = await response.json();
                setCollections(collections.map(c =>
                    c.pk === updatedCollection.pk ? updatedCollection : c
                ));
            } else {
                setError('Failed to update collection');
            }
        } catch (err) {
            setError('Failed to update collection');
        }
    };

    const handleSelectCollection = (collection) => {
        setSelectedCollection(collection);
        fetchStickersForCollection(collection?.pk);
    };

    const handleSelectPersonalCollection = () => {
        setSelectedCollection({
            pk: null,
            title: 'Personal Collection',
            is_featured: false,
            sticker_count: personalStickersCount,
        });
        fetchStickersForCollection(null);
    };

    const onDelete = async (sticker) => {
        if (window.confirm('Are you sure you want to delete this sticker? This action cannot be undone.')) {
            try {
                const response = await strRequest(`/api/v2/stickers/${sticker.pk}/`, 'DELETE');
                if (response.ok) {
                    fetchAll();
                } else {
                    setError('Failed to delete sticker');
                }
            } catch (err) {
                setError('Failed to delete sticker');
                throw err;
            }
        }
    };

    useEffect(() => {
        fetchCollections();
    }, []);

    return (
        <div className="row">
          <div className="col-md-4">
            <div className="card mb-4">
              <div className="card-header">
                <h5 className="mb-0">Sticker Collections</h5>
              </div>
              <div className="card-body">
                {loading ? (
                    <p>Loading collections...</p>
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    <>
                      <ul className="list-group mb-3">
                        <li
                            className={`list-group-item d-flex justify-content-between align-items-center ${selectedCollection?.pk === null ? 'active' : ''}`}
                            onClick={handleSelectPersonalCollection}
                            style={{cursor: 'pointer'}}
                        >
                          <div>
                            Personal Collection
                            <small className={`d-block ${selectedCollection?.pk === null ? 'text-white' : 'text-muted'}`}>
                              {personalStickersCount} stickers
                            </small>
                          </div>
                        </li>
                      </ul>

                      {collections.length === 0 ? (
                          <p>No collections found. Create one below.</p>
                      ) : (
                          <ul className="list-group">
                            {collections.map((collection) => (
                                <li
                                    key={collection.pk}
                                    className={`list-group-item d-flex justify-content-between align-items-center ${selectedCollection?.pk === collection.pk ? 'active' : ''}`}
                                    onClick={() => handleSelectCollection(collection)}
                                    style={{cursor: 'pointer'}}
                                >
                                  <div>
                                    {collection.title}
                                    {collection.is_featured && (
                                        <span className={`badge ms-2 ${selectedCollection?.pk === collection.pk ? 'bg-light text-dark' : 'bg-warning'}`}>
                                          Featured
                                        </span>
                                    )}
                                    <small className={`d-block ${selectedCollection?.pk === collection.pk ? 'text-white' : 'text-muted'}`}>
                                      {collection.sticker_count} stickers
                                    </small>
                                  </div>
                                  <button
                                      className={`btn btn-sm ${selectedCollection?.pk === collection.pk ? 'btn-light' : 'btn-outline-primary'}`}
                                      onClick={(e) => {
                                          e.stopPropagation();
                                          handleToggleFeatured(collection);
                                      }}
                                  >
                                    {collection.is_featured ? 'Unfeature' : 'Feature'}
                                  </button>
                                </li>
                            ))}
                          </ul>
                      )}
                    </>
                )}
              </div>
              <div className="card-footer">
                <form onSubmit={handleCreateCollection} className="d-flex">
                  <input
                      type="text"
                      className="form-control me-2"
                      placeholder="New collection title"
                      value={newCollectionTitle}
                      onChange={(e) => setNewCollectionTitle(e.target.value)}
                  />
                  <button type="submit" className="btn btn-primary">Create</button>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="card">
              <div className="card-header">
                <h5 className="mb-0">
                  {selectedCollection ? `Stickers in "${selectedCollection.title}"` : 'Select a collection'}
                </h5>
              </div>
              <div className="card-body">
                {successMessage && (
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                      {successMessage}
                      <button type="button" className="btn-close" onClick={() => setSuccessMessage('')}></button>
                    </div>
                )}
                {error && (
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                      {error}
                      <button type="button" className="btn-close" onClick={() => setError('')}></button>
                    </div>
                )}

                {!selectedCollection ? (
                    <p>Select a collection to view its stickers</p>
                ) : loadingStickers ? (
                    <p>Loading stickers...</p>
                ) : (
                    <div className="row">
                      {stickers.length === 0 ? (
                          <p>No stickers in this collection yet.</p>
                      ) : (
                          stickers.map(sticker => (
                              <div key={sticker.pk} className="col-md-4 mb-3">
                                <div className="card h-100">
                                  <img
                                      src={sticker.image}
                                      className="card-img-top"
                                      alt={sticker.title}
                                      style={{maxHeight: '150px', objectFit: 'contain'}}
                                  />
                                  <div className="card-body">
                                    <h6 className="card-title">{sticker.title}</h6>
                                    <p className="card-text">
                                      <small className="text-muted">
                                        Used {sticker.use_count} times
                                      </small>
                                    </p>

                                    <div className="d-flex flex-column gap-2">
                                      {/* Show collection actions only if we're not in Personal Collection */}
                                      {selectedCollection?.pk !== null && (
                                          <button
                                              className="btn btn-sm btn-outline-danger w-100"
                                              onClick={() => {
                                                  const collectionId = selectedCollection.pk;
                                                  strRequest(`/api/v2/stickers/collections/${collectionId}/`, 'PATCH', {
                                                      action: 'remove',
                                                      sticker_id: sticker.pk,
                                                  }).then(response => {
                                                      if (response.ok) {
                                                          fetchAll();
                                                      } else {
                                                          setError('Failed to remove sticker from collection');
                                                      }
                                                  }).catch(() => {
                                                      setError('Failed to remove sticker from collection');
                                                  });
                                              }}
                                          >
                                            Remove from Collection
                                          </button>
                                      )}

                                      {collections.length > 0 && (
                                          <div className="dropdown">
                                              <button
                                                  className="btn btn-sm btn-outline-secondary w-100 dropdown-toggle"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                              >
                                                Add to Collection
                                              </button>
                                              <div className="dropdown-menu p-3" style={{minWidth: '250px'}}>
                                                <select
                                                    className="form-select form-select-sm mb-2"
                                                    value={targetCollectionIds[sticker.pk] || ''}
                                                    onChange={(e) => setTargetCollectionIds(prev => ({
                                                        ...prev,
                                                        [sticker.pk]: e.target.value,
                                                    }))}
                                                >
                                                    <option value="">Select collection...</option>
                                                    {collections
                                                        .filter(c => c.pk !== selectedCollection?.pk)
                                                        .map(collection => (
                                                            <option key={collection.pk} value={collection.pk}>
                                                                {collection.title}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                                <button
                                                    className="btn btn-sm btn-success w-100"
                                                    onClick={() => addStickerToCollection(sticker.pk, targetCollectionIds[sticker.pk])}
                                                    disabled={!targetCollectionIds[sticker.pk]}
                                                >
                                                    Add
                                                </button>
                                              </div>
                                          </div>
                                      )}

                                      <button
                                          className="btn btn-sm btn-danger w-100"
                                          onClick={() => onDelete(sticker)}
                                      >
                                        Delete Sticker
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          ))
                      )}
                    </div>
                )}
              </div>
            </div>
          </div>
        </div>
    );
}

function StickersUploadForm() {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [collections, setCollections] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        // Fetch collections when component mounts
        const fetchCollections = async () => {
            try {
                const data = await fetchJSON('/api/v2/stickers/collections/');
                setCollections(data);
            } catch (err) {
                setError('Failed to load collections');
            }
        };
        fetchCollections();
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            // Default title to filename if not set
            if (!title) {
                setTitle(selectedFile.name);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setError('Please select a file to upload');
            return;
        }

        setUploading(true);
        setError(null);
        setSuccess(false);

        const formData = new FormData();
        formData.append('image', file);
        if (title) {
            formData.append('title', title);
        }
        // Add selected collections
        if (selectedCollections.length > 0) {
            selectedCollections.forEach(collectionId => {
                formData.append('collections[]', collectionId);
            });
        }

        try {
            const response = await fetch('/api/v2/stickers/', {
                method: 'POST',
                body: formData,
                headers: {
                    "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                },
                // Don't set Content-Type header, let the browser set it with the boundary
            });

            if (response.ok) {
                setSuccess(true);
                setFile(null);
                setTitle('');
                setSelectedCollections([]);
                // Reset the file input
                document.getElementById('stickerFileInput').value = '';
            } else {
                const data = await response.json();
                setError(data.error || 'Failed to upload sticker');
            }
        } catch (err) {
            setError('Failed to upload sticker');
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Upload New Sticker</h5>
          </div>
          <div className="card-body">
            {success && (
                <div className="alert alert-success">
                  Sticker uploaded successfully!
                </div>
            )}
            {error && (
                <div className="alert alert-danger">
                  {error}
                </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="stickerFileInput" className="form-label">Sticker Image (PNG)</label>
                <input
                    type="file"
                    className="form-control"
                    id="stickerFileInput"
                    accept="image/png"
                    onChange={handleFileChange}
                    required
                />
                <div className="form-text">
                  PNG files with transparency are recommended for best results.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="stickerTitle" className="form-label">Title (Optional)</label>
                <input
                    type="text"
                    className="form-control"
                    id="stickerTitle"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Leave blank to use filename"
                />
              </div>
              {collections.length > 0 && (
                  <div className="mb-3">
                    <label className="form-label">Add to Collections (Optional)</label>
                    <select
                        className="form-select"
                        multiple
                        value={selectedCollections}
                        onChange={(e) => setSelectedCollections(
                            Array.from(e.target.selectedOptions, option => option.value)
                        )}
                    >
                        {collections.map(collection => (
                            <option key={collection.pk} value={collection.pk}>
                                {collection.title}
                            </option>
                        ))}
                    </select>
                    <div className="form-text">
                        Hold Ctrl/Cmd to select multiple collections
                    </div>
                  </div>
              )}
              <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={uploading || !file}
              >
                {uploading ? 'Uploading...' : 'Upload Sticker'}
              </button>
            </form>
          </div>
        </div>
    );
}

function StickersAdminApp() {
    return (
        <div className="container mt-4">
          <h1 className="mb-4">Stickers Admin</h1>
          <p className="lead mb-4">
            Manage sticker collections and upload new stickers for use in the mobile app's Collage feature.
          </p>

          <StickersUploadForm />
          <StickersCollectionList />
        </div>
    );
}

export default function(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<StickersAdminApp />);
}
