import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import Select, { components } from 'react-select';
import { debounce } from 'lodash-es';
import { Waypoint } from 'react-waypoint';

import { fetchJSON, pluralize } from './util.jsx';
import RadioInputsRow from './RadioInputsRow.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

const apiUrl = "/api/v2/party_list/";

const partyToValue = (party) => ({
    type: 'party',
    value: `P-${party.pk}`,
    label: party.name,
    date: party.last_add,
    party: {
        ...party,
        thumbnail: party.cover_photo,
    },
    pk: party.pk,
});

const tagToValue = (tag) => ({
    type: 'tag',
    value: `T-${tag.pk}`,
    label: tag.tag,
    date: tag.last_used,
    parties: tag.parties,
    tag,
    pk: tag.pk,
});

const MenuListFooterWithInfiniteScroll = (props) => {
    const { haveMoreOptions, loadMore } = props.selectProps;

    return haveMoreOptions && (
        <div style={{textAlign: "center"}}>
          <Waypoint onEnter={loadMore} />
          <LoadingSpinner />
        </div>
    );
};

MenuListFooterWithInfiniteScroll.propTypes = {
    selectProps: PropTypes.object.isRequired,
};

// This adds a MenuListFooter to the Select components.
const MenuListWithFooter = (props) => {
    const {
        MenuListFooter = null,
    } = props.selectProps.components;

    return (
        <components.MenuList {...props}>
          {props.children}
          {props.children.length && MenuListFooter(props)}
        </components.MenuList>
    );
};

MenuListWithFooter.propTypes = {
    children: PropTypes.node.isRequired,
    selectProps: PropTypes.object.isRequired,
};

const alphaSort = (parties, tags) => {
    const sortByLabelAlpha =
        (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    return [...tags].concat([...parties].sort(sortByLabelAlpha));
};

const recentSort = (parties, tags) => {
    // Separate the results into non-null and null lists.
    const nonNulls = [...parties, ...tags].filter(item => item.date !== null);
    const nullParties = parties.filter(item => item.date === null);
    const nullTags = tags.filter(item => item.date === null);

    // Sort the non-nulls by date
    nonNulls.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Sort the nulls by pk
    const sortNullsByPk = (a, b) => b.pk - a.pk; // Descending order
    nullParties.sort(sortNullsByPk);
    nullTags.sort(sortNullsByPk);

    // Concatenate the lists in the desired order
    return nonNulls.concat(nullParties, nullTags);
};

const PartyPickerV2 = (props) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [usedTags, setUsedTags] = useState(new Set());
    const [nextPartyUrl, setNextPartyUrl] = useState("");
    const [sortBy, setSortBy] = useState("recent");

    // kinda annoying that we have to pass these params in all the time...
    // But we can't use the state vars directly since we're not in a useEffect
    // hook, so the following closure captures the initial value of those
    // vars and never sees the new values.
    //
    // Also... I'm reeeeally wishing I had just made two separate fetching
    // functions (one for parties, one for tags)... Future work...
    const fetchParties = async (
        searchQuery_,
        sortBy_,
        nextPartyUrl_ = null,
        previousParties = null,
        previousTags = null,
    ) => {
        if (nextPartyUrl_ && previousParties === null) {
            console.error("previousParties must be provided when nextPartyUrl_ is provided");
            return [];
        }
        if (nextPartyUrl_ && searchQuery_) {
            console.error("nextPartyUrl_ and searchQuery_ are mutually exclusive");
            return [];
        }
        const isAlphaSort = sortBy_ === "alpha";
        setIsLoading(true);
        try {
            // fetch helper
            const fetchAndFormat = async (searchParams) => {
                const isPartyFetch = searchParams.entity !== "tags";
                let fetchPromise;
                if (searchParams.url) {
                    fetchPromise = fetchJSON(searchParams.url);
                } else {
                    fetchPromise = fetchJSON(apiUrl, null, searchParams);
                }
                const response = await fetchPromise;
                if (isPartyFetch) {
                    setNextPartyUrl(response.next || "");
                }
                if (isPartyFetch) {
                    return response.results.map(partyToValue);
                } else {
                    return response.results
                                   .filter(t => t.parties.length > 0)
                                   .map(tagToValue);
                }
            };

            let parties, tags, searchParams = {};
            if (nextPartyUrl_) {
                // This is a "load more". Append to the previous parties.
                searchParams.url = nextPartyUrl_;
                parties = await fetchAndFormat(searchParams);
                parties = [...previousParties, ...parties];
                tags = [...previousTags];
            } else {
                searchParams = {};
                if (searchQuery_)
                    searchParams.q = searchQuery_;
                if (sortBy_)
                    searchParams.sort_by = sortBy_;
                if (isAlphaSort && !searchQuery_) {
                    parties = await fetchAndFormat(searchParams);
                    // No tags in A-Z sort since they're annoying at the
                    // front of the list and tedious to work with at the
                    // end of the list (have to wait for all party paging
                    // to finish and then we'd have to page the tags).
                    // They can still search and find tags while in A-Z.
                    tags = [];
                } else {
                    [parties, tags] = await Promise.all([
                        fetchAndFormat(searchParams),
                        fetchAndFormat({...searchParams, entity: "tags"}),
                    ]);
                }
            }

            const finalResults = isAlphaSort
                               ? alphaSort(parties, tags)
                               : recentSort(parties, tags);
            setOptions(finalResults);
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    // Need to filter out used tags (since they aren't part of the actual
    // select options, so react-select doesn't filter them out).
    const filterOption = (option, inputValue) => {
        if (option.data.type === "tag")
            return !usedTags.has(option.data.tag.tag);
        return true;
    };

    const formatOptionLabelParty = ({ party }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {party.thumbnail && (
              <img
                  src={party.thumbnail}
                  alt={party.name}
                  style={{
                      width: '50px',
                      marginRight: '10px',
                  }}
              />
          )}
          <span>{party.name}</span>
        </div>
    );

    const formatOptionLabelTag = ({ tag }) => {
        const parties = pluralize("party", tag.parties.length, "parties");
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="monospace"
                    style={{
                        marginRight: "3px",
                        display: "inline-block",
                        position: "relative",
                        top: "2px",
                    }}>#{tag.tag}</span>
              {' '}
              <span style={{fontSize: "14px"}}>(selects {tag.parties.length} {parties})</span>
            </div>
        );
    };

    const formatOptionLabel = (obj) => {
        if (obj.type === "tag")
            return formatOptionLabelTag(obj);
        return formatOptionLabelParty(obj);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchParties = useCallback(
        debounce((searchQuery_, sortBy_, nextPartyUrl_ = null, previousParties = null, previousTags = null) => {
            fetchParties(searchQuery_, sortBy_, nextPartyUrl_, previousParties, previousTags);
        }, 300),
        [],
    );

    useEffect(() => {
        debouncedFetchParties(searchQuery, sortBy);
    }, [debouncedFetchParties, searchQuery, sortBy]);

    // Currently only supports loading more parties
    const loadMore = () => {
        const previousParties = options.filter(o => o.type === "party");
        const previousTags = options.filter(o => o.type === "tag");
        debouncedFetchParties(
            null,
            sortBy,
            nextPartyUrl,
            previousParties,
            previousTags,
        );
    };

    const handleInputChange = (newValue) => {
        setNextPartyUrl("");
        setSearchQuery(newValue);
    };

    const handleSortChange = (newValue) => {
        setNextPartyUrl("");
        setSearchQuery("");
        setSortBy(newValue);
    };

    const onChange = (values) => {
        let newUsedTags = new Set(usedTags);
        let selectedParties = [];
        let updatedValues = [...values]; // clone to prevent direct mutation
        const selectedPartyPks = new Set(
            values
                .filter(val => val.type === "party")
                .map(val => val.party.pk)
        );

        for (const value of values) {
            if (value.type === "tag") {
                // All parties tagged with this tag, minus already selected parties
                const taggedParties = value.parties
                                           .filter(party => !selectedPartyPks.has(party.pk))
                                           .map(party => ({
                                               ...party,
                                               tagSelectionSource: value.tag.tag,
                                           }));
                selectedParties.push(...taggedParties);
                // remove the tag from selections
                updatedValues = updatedValues.filter(val => val.value !== value.value);
                updatedValues.push(...taggedParties.map(partyToValue));
                const partiesPlural = pluralize("party", taggedParties.length, "parties");
                toast.success(`Selected ${taggedParties.length} ${partiesPlural} via #${value.tag.tag}`,
                              {autoClose: 2000});
                newUsedTags.add(value.tag.tag);
            } else {
                selectedParties.push(value.party);
            }
        }

        setUsedTags(newUsedTags);
        setSelectedOptions(updatedValues);
        props.onChange(selectedParties);
    };

    return (
        <div>
          <Select
              value={selectedOptions}
              onChange={onChange}
              onInputChange={handleInputChange}
              isLoading={isLoading}
              options={options}
              filterOption={filterOption}
              formatOptionLabel={formatOptionLabel}
              isMulti
              placeholder="Select one or more parties..."
              closeMenuOnSelect={selectedOptions.length === 0}
              haveMoreOptions={!!nextPartyUrl}
              loadMore={loadMore}
              components={{
                  MenuList: MenuListWithFooter,
                  MenuListFooter: MenuListFooterWithInfiniteScroll,
              }}
          />
          <RadioInputsRow
              onChange={handleSortChange}
              label="Sort:"
              initialValue="recent"
              options={[{
                  label: "Recently used",
                  value: "recent",
              }, {
                  label: <i className="fa fa-sort-alpha-asc"
                            style={{fontSize: "larger"}}></i>,
                  value: "alpha",
              }]}
          />
        </div>
    );
};

PartyPickerV2.propTypes = {
    // Gets an array of party objects
    onChange: PropTypes.func.isRequired,
};

function PartyPickerV2App(el, onChange) {
    if (el === null)
        return;
    const appRoot = createRoot(el);
    appRoot.render(<PartyPickerV2 onChange={onChange} />);
}

export { PartyPickerV2, PartyPickerV2App };
