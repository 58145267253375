import React from "react";
import ReactDOMServer from 'react-dom/server';
import PropTypes from "prop-types";
import moment from 'moment';

import { formatCurrency } from './util.jsx';

/* these templates exist because jsx > handlebars */

function Image(props) {
    return <td>
      <div className="enlarge"
           style={{display: "inline-block", verticalAlign: "middle"}}>
        <a target="_blank" href={props.image_full} className="nounderline">
          <img src={props.image_thumbnail} />
        </a>
        <img className="preview-full hidden-xs hidden-sm"
             data-src={props.image_full} />
      </div>
    </td>;
}

Image.propTypes = {
    image_thumbnail: PropTypes.string,
    image_full: PropTypes.string,
};

function Product(props) {
    return <td>
      <div style={{display: "inline-block", verticalAalign: "middle"}}>
        <ul className="list-unstyled text-small">
          {props.title &&
           <li key="keytitle">
             <strong>Title:</strong> {props.title}
           </li>
          }
          {props.itemchoice &&
           <li key="keystyle">
             <strong>{StrUserInfo.words.style}:</strong> {props.itemchoice}
           </li>
          }
          <li key="keyprice">
            <strong>Price/Wholesale:</strong> {formatCurrency(props.price)} / {formatCurrency(props.wholesale_price)}
          </li>
          {props.dropship &&
           <li><strong>Dropship commission:</strong> {formatCurrency(props.dropship_consultant_payout)}</li>
          }
          {!props.hideAllAttributes &&
           Object.entries(props.attributes).map(([attribute, value]) => {
               const key = `{attribute}-{value}`;
               const hidden = props.hiddenAttributes.includes(attribute);
               if (hidden) {
                   return <li key={key}
                              title="Hidden attribute">
                     <span className="label label-light">
                       <i>{attribute}: {value}</i>
                     </span>
                   </li>;
               } else {
                   return <li key={key}>
                     <span className="label label-light">
                       {attribute}: {value}
                     </span>
                   </li>;
               }
           })
          }
        </ul>
      </div>
    </td>;
}

Product.propTypes = {
    string_rep: PropTypes.string,
    title: PropTypes.string,
    itemchoice: PropTypes.string,
    attributes: PropTypes.object,
    hiddenAttributes: PropTypes.array,
    hideAllAttributes: PropTypes.bool,
    price: PropTypes.number,
    wholesale_price: PropTypes.number,
    dropship: PropTypes.bool,
    dropship_consultant_payout: PropTypes.number,
};

function SizeOrCategory(props) {
    return <td>
      {props.size}
      {props.category}
    </td>;
}

SizeOrCategory.propTypes = {
    size: PropTypes.string,
    category: PropTypes.string,
};

function PostsAndParties(props) {
    if (typeof props.fbposts === 'number'
        && typeof props.parties === 'number') {
        return <td>
          <div className="text-small">
            {props.fbposts > 0 &&
             <p>Facebook posts: {props.fbposts}</p>
            }
            {props.parties > 0 &&
             <p>{StrUserInfo.words.parties}: {props.parties}</p>
            }
          </div>
        </td>;
    }
    /* sorting these on the frontend to avoid a join query on the party
     * name on the backend */
    const parties = props.parties.sort((a, b) => {
        if (a.name < b.name)
            return -1;
        if (a.name > b.name)
            return 1;
        return 0;
    });
    return <td>
      <div className="text-tiny">
        {props.fbposts.length > 0 && <p>Facebook posts:</p>}
        {props.fbposts.map(fbpost => {
            let fblinks = [];
            if (fbpost.fb_album.fb_group.link) {
                fblinks.push(
                    [fbpost.fb_album.fb_group.link, fbpost.fb_album.fb_group.name]
                );
            }
            if (fbpost.fb_album.link) {
                fblinks.push([fbpost.fb_album.link, fbpost.fb_album.name]);
            }
            if (fbpost.fb_photo_link) {
                fblinks.push([fbpost.fb_photo_link, 'Photo']);
            }
            return <p key={fbpost.pk}>
              <span className="text-muted">[ </span>
              {fblinks.map((fblink, idx) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return <span key={idx}>
                    <a target="_blank" href={fblink[0]}>
                      {fblink[1]}
                    </a>
                    {idx != (fblinks.length - 1) && ' | '}
                  </span>;
              })}
              {fbpost.post_later &&
               <span>
                 <span data-toggle="tooltip"
                       title={`Queued for posting to '${fbpost.fb_album.name}' on Facebook at this time: ${fbpost.post_later}`}>
                   {' '}
                   (Facebook post queued)
                   {' '}
                 </span>
                 (<a href="/status">System status</a>)
               </span>
              }
              {fbpost.fb_error_message &&
               <span>
                 <span className="text-danger">
                   {' '}
                   (Facebook error)
                   {' '}
                 </span>
                 <a href="/facebook_troubleshooting/"
                    className="text-muted"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    data-html="true"
                    title={`Some common reasons for post failures include: your albums/groups being deleted, being thrown in Facebook jail, or not being an admin of your group. Click the question mark for more info. If none of these apply to you please contact support.<br><br>Full error message:<br><br>${fbpost.fb_error_message }`}>
                   (?)
                 </a>
               </span>
              }
              <span className="text-muted"> ]</span>
            </p>;
        })}

        {(parties.length > 0 && props.fbposts.length > 0) &&
         <hr style={{margin: "0"}} />
        }

        {parties.length > 0 &&
         <p style={{margin: "0"}}>
           {StrUserInfo.words.parties}:
         </p>
        }

        <ul className="list-inline">
          {parties.map(party => {
              return <li key={party.pk}>
                <span className="text-muted">[ </span>
                <a title={party.name}
                   href={party.url}>
                  {party.name}
                </a>
                <span className="text-muted"> ]</span>
              </li>;
          })}
        </ul>
      </div>
    </td>;
}

PostsAndParties.propTypes = {
    // deprecated
    fbposts: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.number,
    ]),
    parties: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.number,
    ]),
};

function Quantity(props) {
    return <td>
      {props.quantity}
      {props.quantity_sold > 0 &&
       <span>
         {' '}
         listed
         <br />
         <span className="text-muted">
           ({props.quantity_sold} sold)
         </span>
       </span>
      }
      {props.quantity === 0 &&
       <div>
         <span className="label label-danger"
               style={{fontSize: "9px"}}>Out of stock</span>
       </div>
      }
    </td>;
}

Quantity.propTypes = {
    quantity: PropTypes.number,
    quantity_sold: PropTypes.number,
};

function FbComments(props) {
    if (typeof props.fbposts === 'number') {
        return null;
    }
    return <td>
      {props.fbposts.map(fbpost => {
          return <div key={fbpost.pk}>
            {fbpost.fbcomment_set.length > 0 &&
             <h6>
               Comments in {fbpost.fb_album.fb_group.name} / {fbpost.fb_album.name}
             </h6>
            }
            {fbpost.fbcomment_set.map(fbcomment => {
                const interestingCls = fbcomment.interesting_comment
                                     ? " interesting-comment" : "";
                return <p key={fbcomment.pk}
                          className={`text-tiny${interestingCls}`}>
                  {' '}
                  <i>{fbcomment.message}</i>
                  {' '}
                  <span className="text-muted text-tiny">
                    ({moment(fbcomment.created_time).format('LLLL')})
                  </span>
                </p>;
            })}
          </div>;
      })}
    </td>;
}

FbComments.propTypes = {
    fbposts: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.number,
    ]),
};

function Tools(props) {
    return (
        <td>
          <div className="btn-group" role="group" aria-label="Actions">
            <div className="btn-group-vertical" role="group" aria-label="Actions">
              {!props.dropship && props.market_edit_url &&
               <a className="btn btn-default"
                  style={{cursor: "pointer"}}
                  href={props.market_edit_url}
                  title="Edit">
                 <span className="glyphicon glyphicon-edit"></span>
                 {' '}
                 Edit
               </a>
              }
              {!props.dropship && !props.market_edit_url &&
               <a className="btn btn-default loadInvItemEditor"
                  data-itempk={props.pk}
                  style={{cursor: "pointer"}}
                  title="Edit">
                 <span className="glyphicon glyphicon-edit"></span>
                 {' '}
                 Edit
               </a>
              }
              <a className="btn btn-default"
                 href={props.buyitem_url}
                 title="Customer View">
                <span className="glyphicon glyphicon-user"></span>
                {' '}
                Customer view
              </a>
              {!props.dropship && props.sold_one_item_url &&
               <a className="btn btn-default"
                  href={props.sold_one_item_url}
                  title="Sold outside of Sonlet">
                 <span className="glyphicon glyphicon-transfer"></span>
                 {' '}
                 Sold outside of Sonlet
               </a>
              }
            </div>
          </div>
        </td>
    );
}

Tools.propTypes = {
    pk: PropTypes.number,
    listings_url: PropTypes.string,
    buyitem_url: PropTypes.string,
    sold_one_item_url: PropTypes.string,
    market_edit_url: PropTypes.string,
    dropship: PropTypes.bool,
    itemchoice: PropTypes.string,
};

const ListingsTemplates = {
    Image: (data) => ReactDOMServer.renderToStaticMarkup(
        <Image {...data} />
    ),
    Product: (data) => ReactDOMServer.renderToStaticMarkup(
        <Product {...data} />
    ),
    SizeOrCategory: (data) => ReactDOMServer.renderToStaticMarkup(
        <SizeOrCategory {...data} />
    ),
    PostsAndParties: (data) => ReactDOMServer.renderToStaticMarkup(
        <PostsAndParties {...data} />
    ),
    Quantity: (data) => ReactDOMServer.renderToStaticMarkup(
        <Quantity {...data} />
    ),
    FbComments: (data) => ReactDOMServer.renderToStaticMarkup(
        <FbComments {...data} />
    ),
    Tools: (data) => ReactDOMServer.renderToStaticMarkup(
        <Tools {...data} />
    ),
};

export default ListingsTemplates;
