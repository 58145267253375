import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { fetchJSON, strRequest } from '../util';

function CollagesList() {
    const [collages, setCollages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [quota, setQuota] = useState(null);

    const handleDelete = async (collageId) => {
        if (!confirm('Are you sure you want to delete this collage?')) {
            return;
        }

        try {
            const response = await strRequest('/api/v2/collages/', 'DELETE', { collage_id: collageId });
            if (response === null) return; // Request was aborted

            if (response.ok) {
                setCollages(collages.filter(c => c.pk !== collageId));
            } else {
                alert('Failed to delete collage');
            }
        } catch (err) {
            alert('Failed to delete collage');
        }
    };

    const handleToggleFavorite = async (collageId, currentFavoriteStatus) => {
        try {
            // Send the opposite of the current status to toggle it
            const favorite = !currentFavoriteStatus;

            const response = await strRequest('/api/v2/collages/', 'PATCH', {
                collage_id: collageId,
                favorite: favorite,
            });

            if (response === null) return; // Request was aborted

            if (response.ok) {
                const updatedCollage = await response.json();
                setCollages(collages.map(c => c.pk === collageId ? updatedCollage : c));
            } else {
                alert('Failed to update favorite status');
            }
        } catch (err) {
            alert('Failed to update favorite status');
        }
    };

    useEffect(() => {
        const fetchCollages = async () => {
            try {
                const data = await fetchJSON(`/api/v2/collages/`, null, { page });
                if (data === null) return;

                setCollages(prev => page === 1 ? data.collages : [...prev, ...data.collages]);
                setHasMore(data.next !== null);
                if (page === 1) {
                    setQuota(data.quota);
                }
                setLoading(false);
            } catch (err) {
                setError('Failed to load collages');
                setLoading(false);
            }
        };

        fetchCollages();
    }, [page]);

    // Handle load more button click
    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    if (loading && collages.length === 0) {
        return <div className="text-center p-4">Loading...</div>;
    }

    if (error) {
        return <div className="alert alert-danger">{error}</div>;
    }

    if (collages.length === 0) {
        return (
            <div className="text-center p-4">
                <p>You haven't created any collages yet.</p>
                <p>Create some in the <a href="/apps/">Sonlet Retailer app</a> to get started!</p>
            </div>
        );
    }

    return (
        <div>
            {quota && quota.limit !== null && (
                <div className="mb-3">
                    <div className="alert alert-info">
                        <strong>Collage Quota:</strong> {quota.remaining} of {quota.limit} remaining
                        {quota.reset_date && (
                            <span> (resets on {new Date(quota.reset_date).toLocaleDateString()})</span>
                        )}
                    </div>
                </div>
            )}

            <div className="row">
                {collages.map((collage) => (
                    <div key={collage.pk} className="col-md-4 mb-4">
                        <div className="card h-100">
                            <img
                                src={collage.image_medium}
                                className="card-img-top"
                                alt="Collage"
                                style={{ height: '200px', objectFit: 'cover' }}
                            />
                            <div className="card-body d-flex flex-column">
                                <p className="card-text text-muted">
                                    Created {new Date(collage.created_at).toLocaleDateString()}
                                </p>
                                <div className="mt-auto d-flex justify-content-between">
                                    <button
                                        className="btn btn-sm btn-outline-danger"
                                        onClick={() => handleDelete(collage.pk)}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        className={`btn btn-sm ${collage.favorited_at ? 'btn-warning' : 'btn-outline-warning'}`}
                                        onClick={() => handleToggleFavorite(collage.pk, !!collage.favorited_at)}
                                        title={collage.favorited_at ? 'Remove from favorites' : 'Add to favorites'}
                                    >
                                        <i className="fas fa-star"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {hasMore && (
                <div className="text-center my-4">
                    <button
                        className="btn btn-primary"
                        onClick={loadMore}
                        disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Load More'}
                    </button>
                </div>
            )}
        </div>
    );
}

function CollagesApp() {
    return (
        <div className="container mt-4">
          <h1 className="mb-4">My Collages</h1>
          <CollagesList />
        </div>
    );
}

export default function(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<CollagesApp />);
}
