import React from 'react';
import { createRoot } from 'react-dom/client';

import NotificationsList from './NotificationsList.jsx';

class StrNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            expanded: false,
            numMarkedRead: 0,
        };
        this.hasError = false;
        this.handleClick = this.handleClick.bind(this);
        this.handleBodyClick = this.handleBodyClick.bind(this);
        this.fetch = this.fetch.bind(this);
    }

    render() {
        if (str_user_id === -1)
            return null;

        const unread = this.state.notifications.reduce((count, notification) => {
            return notification.read ? count : count + 1;
        }, 0);
        let bubClass = "strNotificationBubble";
        if (this.state.expanded)
            bubClass += " active";
        let badgeClass = "badge";
        if (unread === 0)
            badgeClass += " badge-inactive";
        else
            badgeClass += " alert-info";

        return (
            <div className="StrNotificationsRoot">
              <div className={bubClass} onClick={this.handleClick}>
                <span className={badgeClass}>{unread || '0'}</span>
              </div>
              {this.state.expanded &&
               <NotificationsList notifications={this.state.notifications.slice(0,5)} />
              }
            </div>
        );
    }

    fetch() {
        if (this.hasError) {
            return;
        }

        var self = this;
        $.getJSON('/api/v2/notifications/', {
            recent: true,
        })
         .done(data => self.setState({
             notifications: data.results,
         }))
         .fail(error => {
             console.error('Failed to fetch notifications:', error);
             this.hasError = true;
         });
    }

    componentDidMount() {
        var self = this;
        this.fetch();
        StrApp.strWebsocket(
            'private-u-' + str_user_id + '-notification',
            data => {
                if (!(data.subtype === "clear" || data.subtype === "new")) {
                    return;
                }
                self.setState(function(prevState, props) {
                    if (data.subtype === 'clear') {
                        return {notifications: []};
                    }
                    return {
                        notifications: [data.data]
                            .concat(prevState.notifications),
                    };
                });
            },
            this.fetch,
        );
        document.body.addEventListener('click', this.handleBodyClick);

        $(window).load(function() {
            $('#HW_badge_cont').click(self.handleBodyClick);
        });
    }

    handleClick() {
        if (!this.state.expanded
            && this.state.notifications.length - this.state.numMarkedRead) {
            $.get('/notifications/allread/');
            const markedNots = this.state.notifications.map(notification => {
                notification.read = true;
                return notification;
            });

            this.setState({
                expanded: true,
                notifications: markedNots,
            });
        } else {
            this.setState((prevState, props) => ({
                expanded: !prevState.expanded,
            }));
        }

    }

    handleBodyClick(e) {
        if ($(e.target).closest('.StrNotificationsRoot').length === 0)
            this.setState({expanded: false});
    }

}

function Notifications(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<StrNotifications />);
}

export default Notifications;
