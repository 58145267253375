import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ImageUploadOrSelect from "./ImageUploadOrSelect.jsx";
import Alert from "./Alert.jsx";
import Sortable from "./Sortable.jsx";
import ExtraImageListItem from "./ExtraImageListItem.jsx";
import { fetchJSON, strRequestJSON } from "./util.jsx";

const ExtraImagesContainer = ({
    invItemPk,
    extraImages = [],
    showUploadWidget = false,
    resource = "extraimages",
    setImages,
}) => {
    const [error, setError] = useState("");
    const [currentImages, setCurrentImages] = useState(extraImages);
    const [isUploadWidgetVisible, setIsUploadWidgetVisible] = useState(showUploadWidget);

    useEffect(() => {
        if (invItemPk && currentImages.length === 0) {
            fetchData(invItemPk);
        }
    }, [invItemPk, currentImages]);

    const fetchData = async (pk) => {
        const url = `/api/v2/items/${pk}/`;
        const data = await fetchJSON(url);
        setCurrentImages(data.extra_images);
    };

    const onDelete = (success, image, rsp) => {
        if (!success) {
            let reason = "We could not delete this image";
            if (rsp && rsp.responseJSON && rsp.responseJSON.length > 0) {
                reason = rsp.responseJSON[0];
            }
            setError(reason);
        }

        const newImages = currentImages.filter(x => x.id !== image.id);
        setCurrentImages(newImages);
        notifyNewImages(newImages);
    };

    const updateSortOrder = (image, position) => {
        const url = `/api/v2/${resource}/${image.id}/`;
        strRequestJSON(url, "PATCH", { sort_order: position });
    };

    const onSort = (currentPosition, newPosition) => {
        const newImages = [...currentImages];
        const [draggedItem] = newImages.splice(currentPosition, 1);
        newImages.splice(newPosition, 0, draggedItem);
        setCurrentImages(newImages);
        notifyNewImages(newImages);
        newImages.forEach(updateSortOrder);
    };

    const notifyNewImages = (newImages) => {
        if (setImages) {
            setImages(newImages);
        }
    };

    const onAdd = (result) => {
        const image = result.file;
        image.url = image.image_full;
        const newImages = [...currentImages, image];
        setCurrentImages(newImages);
        notifyNewImages(newImages);
    };

    return (
        <div className="row">
          <div className="col-md-12" style={{padding: 0}}>
            <DndProvider backend={HTML5Backend}>
              <div className="invitem-image-container">
                {currentImages.map((image, i) => (
                    <Sortable
                        id={image.id}
                        key={image.id}
                        index={i}
                        moveItem={onSort}
                        content={
                            <ExtraImageListItem
                                image={image}
                                onDelete={onDelete}
                                endpoint={resource}
                            />
                        }
                    />
                ))}

                {!isUploadWidgetVisible && (
                    <button className="btn btn-default btn-sm"
                            onClick={() => setIsUploadWidgetVisible(true)}>
                      Add more images
                    </button>
                )}

                {error && (
                    <Alert
                        heading="An error occurred!"
                        message={error}
                        type="danger"
                    />
                )}
              </div>
            </DndProvider>

            {isUploadWidgetVisible &&
             <ImageUploadOrSelect
                 inputName="upload_extra_images"
                 callback={onAdd}
                 resource={resource}
                 extraImagesLength={currentImages.length}
                 invItemPk={invItemPk}
                 multiple={true}
             />
            }
          </div>
        </div>
    );
};

ExtraImagesContainer.propTypes = {
    invItemPk: PropTypes.number,
    extraImages: PropTypes.array,
    showUploadWidget: PropTypes.bool,
    resource: PropTypes.string,
    setImages: PropTypes.func,
};

export default ExtraImagesContainer;
