import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

const ENDPOINTS = {
    extraimages: '/api/v2/extraimages/',
    genimage: '/api/v2/genimage/',
    invitemimage: '/api/v2/invitemimage/',
    default: '/api/v2/images/',
    misc: '/api/v2/images/',
};

const ImageUploadOrSelectV2 = ({
    inputName,
    onProgress,
    onComplete,
    onInitialDrop,
    multiple = false,
    showThumbnails = false,
    showUploadStatus = true,
    invItem = false,
    invItemPk = null,
    extraImagesLength = null,
    resource = null,
    genImageType = null,
}) => {
    const [files, setFiles] = useState([]);
    const [rejections, setRejections] = useState([]);

    const uploadFile = useCallback(async (fileObj, options = {}) => {
        const { file, uuid } = fileObj;
        const formData = new FormData();
        formData.append('image_full', file);

        // Add extra fields based on resource type
        if (resource === 'extraimages' || resource === 'invitemimage') {
            if (options.sortOrder) formData.append('sort_order', options.sortOrder);
            if (invItemPk) formData.append('item', invItemPk);
        }

        if (resource === 'genimage' && genImageType) {
            formData.append('image_type', genImageType);
        }

        // Determine endpoint
        let endpoint = ENDPOINTS[resource] ||
                       (invItem ? ENDPOINTS.default : ENDPOINTS.misc);

        // Create and configure XHR
        const xhr = new XMLHttpRequest();

        // Set up progress tracking
        xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable && onProgress) {
                onProgress({
                    uuid,
                    percent: Math.round((event.loaded / event.total) * 100),
                });
            }
        });

        // Return promise for upload completion
        return new Promise((resolve, reject) => {
            xhr.onload = () => {
                if (xhr.status === 200 || xhr.status === 201) {
                    try {
                        const response = JSON.parse(xhr.responseText);
                        if (onComplete) {
                            onComplete({
                                uuid,
                                file: response,
                                status: 'complete',
                            }, file);
                        }
                        resolve(response);
                    } catch (e) {
                        reject(new Error('Invalid response format'));
                    }
                } else {
                    reject(new Error(xhr.responseText || 'Upload failed'));
                }
            };

            xhr.onerror = () => reject(new Error('Network error'));

            xhr.open('POST', endpoint);
            xhr.setRequestHeader('X-CSRFToken', Cookies.get(strConfig.csrfCookieName));
            xhr.setRequestHeader('Accept', 'application/json');

            xhr.send(formData);
        });
    }, [onProgress, onComplete, invItem, invItemPk, resource, genImageType]);

    const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
        setRejections(fileRejections);

        // Handle accepted files
        const newFiles = acceptedFiles.map(file => ({
            file,
            uuid: crypto.randomUUID(),
            status: 'uploading',
        }));

        setFiles(prev => [...prev, ...newFiles]);

        // Notify parent of new files immediately
        newFiles.forEach(fileObj => {
            onInitialDrop && onInitialDrop(fileObj);
        });

        // Upload files
        for (const fileObj of newFiles) {
            try {
                const sortOrder = extraImagesLength ?
                                  extraImagesLength + newFiles.indexOf(fileObj) + 1 : null;

                await uploadFile(fileObj, { sortOrder });

                setFiles(prev => prev.map(f =>
                    f.uuid === fileObj.uuid
                    ? { ...f, status: 'complete' }
                    : f
                ));
            } catch (error) {
                setFiles(prev => prev.map(f =>
                    f.uuid === fileObj.uuid
                    ? { ...f, status: 'error', error: error.message }
                    : f
                ));
            }
        }
    }, [uploadFile, extraImagesLength, onInitialDrop]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple,
        maxSize: 10000000, // 10MB
        accept: {
            'image/*': ['.jpeg', '.png', '.jpg'],
        },
    });

    return (
        <div className="str-image-upload">
          <div
              {...getRootProps()}
              className={`str-image-upload-dropzone ${isDragActive ? 'active' : ''}`}
          >
            <input {...getInputProps()} name={inputName} />
            <div className="str-image-upload-dropzone-content">
              <i className="fa fa-cloud-upload" aria-hidden="true" />
              <div>
                Drag and Drop or Select File{multiple ? 's' : ''}
              </div>
            </div>
          </div>

          {showUploadStatus && (files.length > 0 || rejections.length > 0) && (
              <div className="str-image-upload-status">
                <ul className="str-image-upload-file-list">
                  {files.map(fileObj => (
                      <li key={fileObj.uuid} className={`str-image-upload-file-item ${fileObj.status}`}>
                        <span className="str-image-upload-filename">
                          {fileObj.file.name}
                        </span>
                        {fileObj.status === 'uploading' && (
                            <i className="fa fa-spinner fa-spin" />
                        )}
                        {fileObj.status === 'complete' && (
                            <>
                              <i className="fa fa-check text-success" />
                              {showThumbnails && fileObj.result?.thumbnailUrl && (
                                  <img
                                      src={fileObj.result.thumbnailUrl}
                                      alt=""
                                      className="str-image-upload-thumbnail"
                                  />
                              )}
                            </>
                        )}
                        {fileObj.status === 'error' && (
                            <span className="str-image-upload-error">
                              {fileObj.error}
                            </span>
                        )}
                      </li>
                  ))}
                </ul>

                {rejections.length > 0 && (
                    <div className="str-image-upload-rejections">
                      <p>Couldn't upload:</p>
                      <ul>
                        {rejections.map((rejection, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={index}>
                              {rejection.file.name}: {rejection.errors.map(e => e.message).join(', ')}
                            </li>
                        ))}
                      </ul>
                      <p className="str-image-upload-help-text">
                        This could be due to an invalid or corrupt file.
                        Please try another image file that is less than 10MB in size.
                      </p>
                    </div>
                )}
              </div>
          )}
        </div>
    );
};

ImageUploadOrSelectV2.propTypes = {
    inputName: PropTypes.string.isRequired,
    onProgress: PropTypes.func,
    onComplete: PropTypes.func,
    onInitialDrop: PropTypes.func,
    multiple: PropTypes.bool,
    showThumbnails: PropTypes.bool,
    showUploadStatus: PropTypes.bool,
    invItem: PropTypes.bool,
    invItemPk: PropTypes.number,
    extraImagesLength: PropTypes.number,
    resource: PropTypes.string,
    genImageType: PropTypes.string,
};

export default ImageUploadOrSelectV2;
