import React, { useState, Fragment, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import { strRequestJSON, strRequest, strRequestJSONWithResponse } from './util.jsx';
import AdminRemoveItem from './AdminRemoveItem.jsx';

const albumContainerClasses = [
    'col-md-4',
    'col-lg-3',
    'col-sm-6',
    'col-xs-12',
    'party-style-container',
    'pad-sides-5',
].join(' ');

export default function StrPartyAlbum(props) {
    const [extraMessage, setExtraMessage] = useState("");
    const [pendingFeaturedInfo, setPendingFeaturedInfo] = useState({
        'pendingOperation': false,
        'value': false,
    });
    const [pendingHiddenInfo, setPendingHiddenInfo] = useState({
        'pendingOperation': false,
        'value': false,
    });
    const timerRef = useRef(null);

    const {
        album,
        attrValue,
        isOther,
        collapseGroup,
        groupName,
        minimal = false,
        onEnter,
    } = props;

    const handleEnter = useCallback(() => {
        if (!(minimal && onEnter))
            return;
        timerRef.current = setTimeout(() => {
            onEnter();
        }, 100);
    }, [onEnter, minimal]);

    const handleLeave = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    // clean up timer when component unmounts
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    let attributeParam = '';
    if (attrValue) {
        if (isOther) {
            attributeParam = '?nv=1';
        } else {
            attributeParam = `?v=${encodeURIComponent(attrValue)}`;
        }
    }
    const albumUrl = `${album.url}${attributeParam}`;
    let thumbnails = [];
    if (album.cover && album.cover.thumbnail && album.cover.show_thumbs) {
        thumbnails.push(album.cover.thumbnail_url);
    }
    thumbnails = thumbnails.concat(album.thumbnails);

    const isFeatured = pendingFeaturedInfo.pendingOperation
                     ? pendingFeaturedInfo.value
                     : album.is_featured;

    const onFeatureClick = async (e, album) => {
        e.preventDefault();
        const newFeaturedVal = !isFeatured;
        const apiUrl = `/api/v2/admin_feature_party_album/${str_data_party_public_id}/${album.custom_album_pk}/`;
        const [jsonData, response] = await strRequestJSONWithResponse(apiUrl, 'POST', {
            is_featured: newFeaturedVal,
        });

        if (!response || !response.ok) {
            setExtraMessage(jsonData?.error || 'Failed to update album feature status');
            return;
        }

        setExtraMessage(jsonData.result);
        setPendingFeaturedInfo({
            'pendingOperation': true,
            'value': newFeaturedVal,
        });
    };

    const isHidden = pendingHiddenInfo.pendingOperation
                   ? pendingHiddenInfo.value
                   : album.hidden;

    const onHideClick = async (e, album) => {
        e.preventDefault();
        const newHiddenVal = !isHidden;
        const apiUrl = `/api/v2/party_custom_album_set_hidden/${str_data_party_public_id}/${album.custom_album_pk}/`;
        const [jsonData, response] = await strRequestJSONWithResponse(apiUrl, 'POST', {
            hidden: newHiddenVal,
        });

        if (!response || !response.ok) {
            setExtraMessage(jsonData?.error || 'Failed to update album visibility');
            return;
        }

        setExtraMessage(jsonData.result);
        setPendingHiddenInfo({
            'pendingOperation': true,
            'value': newHiddenVal,
        });
    };

    let pitemClasses = "party-item";
    if (isFeatured)
        pitemClasses += " featured-party-item";
    if (collapseGroup)
        pitemClasses += " party-album-group-item";
    if (album.custom_album_pk)
        pitemClasses += ` party-custom-album-${album.custom_album_pk}`;
    if (isHidden)
        pitemClasses += " hidden-party-item";

    const numSizes = album.sizecounts.length;
    // roughly 4 sizes per row on average (needed for placeholder
    // sizes area for minimal mode)
    const numSizeRows = Math.floor(numSizes / 4);

    if (isHidden && !StrUserInfo.ownerInHere) {
        return null;
    }

    return (
        <Waypoint onEnter={handleEnter} onLeave={handleLeave}>
          <div className={albumContainerClasses}>
            <div className={pitemClasses}>
              {isHidden &&
               <span className="label label-default"
                     title="This album is currently only visible to party admins."
                     style={{
                         position: 'absolute',
                         top: '5px',
                         left: '5px',
                     }}>
                 <i className="fa fa-eye-slash"></i> Hidden
               </span>
              }
              <h3 style={{marginTop: '5px', textAlign: 'center'}}>
                {album.name}
              </h3>
              {isFeatured &&
               <Fragment>
                 <i className="fa fa-star featured-party-item-star"
                    aria-hidden="true"></i>
                 <i className="fa fa-star featured-party-item-star featured-party-item-star-back"
                    aria-hidden="true"></i>
               </Fragment>
              }
              {album.cover && album.cover.description &&
               <div className="text-small text-muted"
                    dangerouslySetInnerHTML={{__html: album.cover.description}}
                    style={{textAlign: 'center', marginBottom: '10px'}}></div>
              }
              {album.cover && album.cover.thumbnail && !album.cover.show_thumbs &&
               <div className="clearfix">
                 <a href={albumUrl}>
                   <img className="img-rounded party-big-thumbnail"
                        src={album.cover.image_medium_url} />
                 </a>
               </div>
              }
              {minimal && <div className="pthumb-0"></div>}
              {!minimal &&
               <div>
                 {thumbnails.map((thumbnail, idx) => {
                     return (
                         // thumbnails can share the same url otherwise that
                         // would be the key
                         // eslint-disable-next-line react/no-array-index-key
                         <div key={idx}
                              className="col-xs-6 pthumb-1">
                           <a href={albumUrl}>
                             <img className="img-rounded party-thumbnail img-responsive"
                                  src={thumbnail} />
                           </a>
                         </div>
                     );
                 })}
               </div>
              }
              <div className="clearfix" style={{marginTop: '10px'}}></div>
              {minimal &&
               <div className={`psize-min${numSizeRows}`}></div>
              }
              {!minimal && album.sizecounts.map(sizecount => {
                  if (sizecount.hidden) {
                      return null;
                  }
                  return <a className="btn btn-xs btn-default-subtle psize-btn"
                            key={sizecount.size}
                            href={`${sizecount.url}${attributeParam}`}>
                    {sizecount.size}{' '}
                    <span className="badge">{sizecount.size__count}</span>
                  </a>;
              })}

              <a className="btn btn-xs btn-default-subtle"
                 style={{marginBottom: '5px'}}
                 href={albumUrl}>
                All Items{' '}
                <span className="badge">{album.all_count}</span>
              </a>

              {StrUserInfo.ownerInHere && !minimal &&
               <div style={{marginTop: '5px'}}>
                 <a href={album.cover_edit_url}
                    style={{marginRight: '5px'}}
                    className="btn btn-default btn-xs">
                   Edit album cover
                 </a>
                 <div className="btn-group btn-group-xs">
                   <AdminRemoveItem partyPublicId={str_data_party_public_id}
                                    stylePk={album.itemchoice__pk}
                                    customAlbumPk={album.custom_album_pk}
                                    attributeValue={attrValue && !isOther && attrValue}
                                    count={album.all_count} />
                   <button className="btn btn-default"
                           title={isFeatured ? "Unfeature album" : "Feature album"}
                           onClick={(e) => onFeatureClick(e, album)}>
                     {isFeatured &&
                      <i className="fa fa-star" style={{color: '#ffc000'}} aria-hidden="true"></i>
                     }
                     {isFeatured ||
                      <i className="fa fa-star-o" aria-hidden="true"></i>
                     }
                   </button>
                   <button className="btn btn-default"
                           title={isHidden ? "Unhide album" : "Hide album"}
                           onClick={(e) => onHideClick(e, album)}>
                     <i className={`fa fa-${isHidden ? 'eye-slash' : 'eye'}`}
                        aria-hidden="true"></i>
                   </button>
                 </div>
                 {extraMessage &&
                  <p className="alert alert-info text-small" style={{marginTop: "10px"}}>{extraMessage}</p>
                 }
               </div>
              }
              {collapseGroup && (
                  <div>
                    <hr style={{margin: "5px"}} />
                    <div className="text-small text-muted pull-right">
                      <i>{groupName} album group</i>
                      <button className="btn btn-link btn-xs"
                              style={{paddingTop: "0", marginTop: "0"}}
                              onClick={collapseGroup}>
                        Collapse <i className="fa fa-compress"></i>
                      </button>
                    </div>
                    <div className="clearfix"></div>
                  </div>
              )}
            </div>
          </div>
        </Waypoint>
    );
}

StrPartyAlbum.propTypes = {
    album: PropTypes.object.isRequired,
    attrValue: PropTypes.string,
    isOther: PropTypes.bool,
    collapseGroup: PropTypes.func,
    groupName: PropTypes.string,
    minimal: PropTypes.bool,
    onEnter: PropTypes.func,
};

export function StrPartyAlbumSet(props) {
    const {
        name,
        albums,
        isFavoriteGroup,
        onFeaturedGroupChange,
        minimal = false,
        onEnter,
    } = props;
    const [expanded, setExpanded] = useState(false);

    if (albums.length === 1)
        return <StrPartyAlbum album={albums[0]}
                              minimal={minimal}
                              onEnter={onEnter} />;

    if (expanded) {
        return (
            <Fragment>
              {albums.map(albee => <StrPartyAlbum
                                       album={albee}
                                       key={albee.url}
                                       collapseGroup={() => setExpanded(false)}
                                       groupName={name}
                                       minimal={false}
                                       onEnter={onEnter} />)
              }
            </Fragment>
        );
    }

    const onFeatureGroupClick = (e, name, isFavoriteGroup) => {
        e.preventDefault();
        const action = isFavoriteGroup ? "unstar" : "star";
        const group_prefix = name;
        const data = {
            action,
            group_prefix,
        };
        const apiUrl = `/api/v2/starred_groups/${str_data_party_public_id}/`;
        strRequest(apiUrl, 'POST', data).then((res) => {
            if (res.ok)
                onFeaturedGroupChange();
        });
    };

    const thumbnails = [];
    for (const album of albums) {
        let thumbUrl;
        if (album.cover && album.cover.thumbnail_url)
            thumbUrl = album.cover.thumbnail_url;
        else
            thumbUrl = album.thumbnails[0];
        thumbnails.push({
            url: thumbUrl,
        });
        /* show max of 4 thumbnails in the stack */
        if (thumbnails.length === 4)
            break;
    }
    const degreesPerItem = 5;
    /* shift everything by half so that they fan out symmetrically */
    const rotateShift = (thumbnails.length * degreesPerItem) / 2;
    return (
        <div className={albumContainerClasses}>
          <div className="party-item party-album-group party-album-group-item">
            <div onClick={e => setExpanded(true)}>
              <h3 style={{marginTop: '5px', textAlign: 'center'}}>
                {name}
              </h3>
              {isFavoriteGroup &&
               <Fragment>
                 <i className="fa fa-star featured-party-item-star"
                    aria-hidden="true"></i>
                 <i className="fa fa-star featured-party-item-star featured-party-item-star-back"
                    aria-hidden="true"></i>
               </Fragment>
              }
              <h6 style={{textAlign: 'center'}}>Album group ({albums.length} albums)</h6>
              <i className="fa fa-th party-album-group-icon"
                 aria-hidden="true"></i>
              <div style={{
                  position: "relative",
                  height: "110px",
                  width: "110px",
                  margin: "0 auto",
              }}>
                {thumbnails.map((thumbnail, idx) => {
                    const degress = (idx * degreesPerItem) - rotateShift;
                    const left = idx * 12;
                    const top = idx * 4;
                    const transform = `rotate(${degress}deg)`;
                    // thumbnails can share the same url otherwise that
                    // would be the key
                    return ( // eslint-disable-next-line react/no-array-index-key
                             <div key={idx}
                                  className="thumbnail-stack-item"
                                  style={{position: 'absolute', top, left, transform}}>
                               <img className="img-rounded party-thumbnail img-responsive"
                                    src={thumbnail.url} />
                             </div>
                    );
                })}
              </div>
              <div className="clearfix" style={{marginTop: '10px'}}></div>
              <h5 style={{textAlign: "center"}}>
                Contents:<br />
                {albums.map(a => a.groupwiseName).join(", ")}
              </h5>
            </div>
            {StrUserInfo.ownerInHere &&
             <div style={{marginTop: '5px'}}>
               <button className="btn btn-default btn-xs"
                       title={isFavoriteGroup ? "Unfeature group" : "Feature group"}
                       onClick={(e) => onFeatureGroupClick(e, name, isFavoriteGroup)}>
                 {isFavoriteGroup &&
                  <i className="fa fa-star" style={{color: '#ffc000'}} aria-hidden="true"></i>
                 }
                 {isFavoriteGroup ||
                  <i className="fa fa-star-o" aria-hidden="true"></i>
                 }
               </button>
             </div>
            }
          </div>
        </div>
    );
}

StrPartyAlbumSet.propTypes = {
    name: PropTypes.string.isRequired,
    /*
     * Array of albums suitable for StrPartyAlbum, with the additional
     * requirement that they each contain a property called groupwiseName,
     * which is what will be used to display the album name while grouped.
     * For example, if an album name is "Americana: Azure" the groupwiseName
     * for a group named "Americana" could be "Azure".
     */
    albums: PropTypes.array.isRequired,
    isFavoriteGroup: PropTypes.bool.isRequired,
    onFeaturedGroupChange: PropTypes.func.isRequired,
    minimal: PropTypes.bool,
    onEnter: PropTypes.func,
};
