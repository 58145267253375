import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import Select from 'react-select';
import { debounce } from 'lodash-es';

import { fetchJSON } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

/**
 * A simplified party picker component specifically for staff use.
 * Fetches and displays parties for a specific user (not the current user).
 */
const StaffPartyPicker = ({ userPk, onChange }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [nextUrl, setNextUrl] = useState('');

    // Staff endpoint URL for fetching parties
    const getApiUrl = () => `/api/v2/staff/user/${userPk}/parties/`;

    /**
     * Transform party data into react-select compatible options
     */
    const partyToOption = (party) => ({
        value: party.pk,
        label: party.name,
        party: party,
    });

    /**
     * Fetch parties from the API
     */
    const fetchParties = async (query, nextUrl = null, previousOptions = []) => {
        if (!userPk) return;

        setIsLoading(true);

        try {
            let response;
            if (nextUrl) {
                response = await fetchJSON(nextUrl);
            } else {
                const searchParams = { sort_by: 'recent' };
                if (query) searchParams.q = query;
                response = await fetchJSON(getApiUrl(), null, searchParams);
            }

            const newParties = response.results.map(partyToOption);
            setNextUrl(response.next || '');

            // If fetching more results, append to previous, otherwise replace
            setOptions(nextUrl ? [...previousOptions, ...newParties] : newParties);
        } catch (error) {
            console.error('Error fetching parties:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Debounced search function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchParties = useCallback(
        debounce((query) => fetchParties(query), 300),
        [userPk]
    );

    // Initial load and search handling
    useEffect(() => {
        debouncedFetchParties(searchQuery);
    }, [debouncedFetchParties, searchQuery, userPk]);

    // Load more parties when user scrolls to bottom
    const loadMore = () => {
        if (nextUrl) {
            fetchParties('', nextUrl, options);
        }
    };

    // Handle input changes for search
    const handleInputChange = (newValue) => {
        setSearchQuery(newValue);
    };

    // Handle selection changes
    const handleChange = (selectedValues) => {
        setSelectedOptions(selectedValues);

        // Call parent onChange with array of party objects
        const selectedParties = selectedValues.map(option => option.party);
        onChange(selectedParties);
    };

    // Custom menu with loading spinner for infinite scroll
    const MenuList = ({ children, ...props }) => {
        const style = {
            textAlign: 'center',
            cursor: 'pointer',
            padding: '8px',
            backgroundColor: '#f8f9fa',
            marginTop: '8px',
            borderRadius: '4px',
        };
        return (
            <div style={{ padding: '8px' }}>
              {children}
              {nextUrl && (
              <div style={style} onClick={loadMore} >
                {isLoading ? <LoadingSpinner /> : "Load more parties"}
              </div>
              )}
            </div>
        );
    };

    MenuList.propTypes = {
        children: PropTypes.object.isRequired,
    };

    // Format the party options with thumbnail if available
    const optionStyle = {
        width: '50px',
        height: '50px',
        objectFit: 'cover',
        marginRight: '10px',
        borderRadius: '4px',
    };
    const formatOptionLabel = (option) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {option.party.cover_photo && (
          <img src={option.party.cover_photo}
               alt={option.label}
               style={optionStyle}
          />
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{option.label}</span>
            {option.party.publicLink && (
            <span className="text-muted text-small">
              {option.party.publicLink}
            </span>
            )}
          </div>
        </div>
    );

    return (
        <div>
          <Select
              value={selectedOptions}
              onChange={handleChange}
              onInputChange={handleInputChange}
              isLoading={isLoading}
              options={options}
              isMulti
              placeholder={`Select parties from this user...`}
              components={{ MenuList }}
              formatOptionLabel={formatOptionLabel}
              noOptionsMessage={() => "No parties found"}
              isClearable
          />
        </div>
    );
};

StaffPartyPicker.propTypes = {
    // The user PK to fetch parties for
    userPk: PropTypes.number.isRequired,
    // Callback function that receives array of selected party objects
    onChange: PropTypes.func.isRequired,
};

/**
 * App wrapper for StaffPartyPicker
 * @param {HTMLElement} el - DOM element to mount the component
 * @param {Function} onChange - Callback receiving array of selected party objects
 * @param {number} userPk - PK of user to fetch parties for
 */
function StaffPartyPickerApp(el, onChange, userPk) {
    if (!el) return;
    const root = createRoot(el);
    root.render(<StaffPartyPicker userPk={userPk} onChange={onChange} />);
}

export { StaffPartyPicker, StaffPartyPickerApp };
