import React from 'react';
import PropTypes from 'prop-types';
import { isEmail } from 'validator';
import { ItemAvails } from './constants.js';
import StrNextButton from './StrNextButton.jsx';
import { fetchJSON, USER_INFO_CHANGE_EVENT_NAME, triggerUserInfoChanged } from './util.jsx';
import { getUniUser } from './UserCache.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import { AddressUpdate, UniUserHasValidAddress } from './AddressUpdate.jsx';
import update from 'immutability-helper';
import { ModalContainer } from './StrModal.jsx';
import Confetti from 'react-dom-confetti';
import VariantPicker from './VariantPicker.jsx';
import moment from 'moment';


const LOCALSTORAGE_KEY = 'strAnonUserInfo';

function anonInfoSave(customerInfo) {
    window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(customerInfo));
    triggerUserInfoChanged();
}

function anonInfoGet() {
    try {
        return JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY));
    } catch(e) {
        return {};
    }
}


class ClaimButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            item: null,
            loading: true,
            claiming: false,
            showAnonClaimModal: false,
            errorMessage: null,
            modalMessage: null,
            customerName: '',
            customerEmail: '',
            customerAddress1: '',
            customerAddress2: '',
            customerCity: '',
            customerState: '',
            customerZipp: '',
            customerPhone: '',
            showAddressUpdateModal: false,
            justDidClaim: false,
            claimQuestions: [],
            showClaimQuestionModal: false,
            uniuser: null,
            /* these minimum required fields are a part of item above so
             * they are duplicated here however for props support where
             * we don't fetch the item on mount i think its best to
             * separate them like this */
            quantity: props.quantity,
            disableAnonClaims: props.disableAnonClaims,
            iclaimed: props.iclaimed,
            words: props.words,
            variantData: props.variantData,
            selectedVariantPk: props.variantData
                            && props.variantData.length > 0
                            && props.variantData.find(v => v.quantity)
                            && props.variantData.find(v => v.quantity).pk,
        };

        const anonData = anonInfoGet();
        if (anonData) {
            this.state = {
                ...this.state,
                ...anonData,
            };
        }

        this.renderAnonClaimModal = this.renderAnonClaimModal.bind(this);
        this.renderForSale = this.renderForSale.bind(this);
        this.renderSold = this.renderSold.bind(this);
        this.claim = this.claim.bind(this);
        this.onClaimClick = this.onClaimClick.bind(this);
        this.onAnonClaimModalClose = this.onAnonClaimModalClose.bind(this);
        this.onAddressUpdateModalClose = this.onAddressUpdateModalClose.bind(this);
        this.onUpdateAddressLinkClick = this.onUpdateAddressLinkClick.bind(this);
        this.fetch = this.fetch.bind(this);
        this.fetchUniUser = this.fetchUniUser.bind(this);
        this.renderClaimQuestions = this.renderClaimQuestions.bind(this);
        this.renderClaimQuestionModal = this.renderClaimQuestionModal.bind(this);
        this.onClaimQuestionModalClose = this.onClaimQuestionModalClose.bind(this);
        this.onVariantPick = this.onVariantPick.bind(this);

        this.btnSize = this.props.btnSize !== null
                     ? this.props.btnSize : "btn-sm";

        this.TRIGGER_FETCH_KEY = `CLAIMBUTTON_TRIGGER_FETCH_KEY_${props.itemPk}`;
        this.aborted = false; // necessary since the UserCache APIs aren't abort-aware
    }

    render() {
        const { errorMessage, uniuser, quantity,
                disableAnonClaims, loading, iclaimed, words,
                justDidClaim, variantData} = this.state;
        const { minHeight } = this.props;
        const neededRenderFields = [quantity, disableAnonClaims, iclaimed, words];
        let content;
        if (!neededRenderFields.every((f) => typeof f !== 'undefined')) {
            /* if we are missing any of the neededRenderFields from state then
             * dont render, this can happen if we are missing fields and
             * then when we try to fetch them we get a 404 (can happen in
             * social fields) */
            content = null;
        } else if (uniuser && !UniUserHasValidAddress(uniuser)) {
            content = this.renderNeedAddress();
        } else if (quantity <= 0) {
            content = this.renderSold();
        } else if (str_user_id === -1 && disableAnonClaims) {
            content = this.renderDisallowAnon();
        } else if ( disableAnonClaims && uniuser && uniuser.is_unverified) {
            content = this.renderDisallowUnverified();
        } else {
            content = this.renderForSale();
        }

        const fettiConfig = {
            spread: "246",
            startVelocity: "32",
            elementCount: "41",
            duration: "2780",
            stagger: "3",
            width: "14px",
            height: "12px",
        };

        return (
            <div style={{padding: "5px 0", minHeight: minHeight}}>
              <div style={{zIndex: 9999, position: "relative"}}>
                <Confetti active={justDidClaim} config={fettiConfig} />
              </div>
              {loading &&
               <div style={{marginBottom: '10px'}}>
                 <LoadingSpinner />
               </div>
              }
              {!!variantData && variantData.length > 0 &&
               <div style={{marginBottom: '10px'}}>
                 <VariantPicker variantData={variantData}
                                onPick={this.onVariantPick} />
               </div>
              }
              {content}
              {errorMessage &&
               <div className="alert alert-danger text-small">{errorMessage}</div>
              }
            </div>
        );
    }

    renderNeedAddress() {
        const { showAddressUpdateModal } = this.state;
        return (
            <div className="text-muted">
              Please{' '}
              <a href="#"
                 onClick={this.onUpdateAddressLinkClick}>
                update your name, address and phone
              </a>
              {' '}to claim.
              {showAddressUpdateModal && this.renderAddressUpdateModal()}
            </div>
        );
    }

    renderClaimedContent() {
        const { justDidClaim, iclaimed, words } = this.state;
        const justDidClaimContent = justDidClaim && (
            <div>
              Please proceed
              to <a href="/done_shopping/">{words.done_shopping.toLowerCase()}</a> to
              pay/receive your invoice.
            </div>
        );
        const verbed = iclaimed.is_batched ? words.bought : words.claimed;
        return iclaimed.date ? (
            <div className="text-small">
              <span className="text-muted">
                You {verbed.toLowerCase()} this item on {moment(iclaimed.date).format('LL')}.
              </span>
              {justDidClaimContent}
            </div>
        ) : null;
    }

    renderForSale() {
        const {
            loading,
            showAnonClaimModal,
            claiming,
            showClaimQuestionModal,
            iclaimed,
            words,
            variantData,
            selectedVariantPk,
        } = this.state;
        const btnProps = {
            disabled: claiming
                   || loading
                   || StrUserInfo.banned
                   || (!!variantData
                    && variantData.length > 0
                    && !selectedVariantPk),
            className: `btn btn-primary ${this.btnSize}`,
            style: {whiteSpace: 'break-spaces'},
            onClick: this.onClaimClick,
        };
        const icon = claiming
                   ? <LoadingSpinner />
                   : <i className="fa fa-shopping-bag"></i>;
        const claimWords = iclaimed.date ? words.claim_another : words.claim;
        return (
            <div>
              <button {...btnProps}>{icon} {claimWords}</button>
              {showAnonClaimModal && this.renderAnonClaimModal()}
              {showClaimQuestionModal && this.renderClaimQuestionModal()}
              {this.renderClaimedContent()}
            </div>
        );
    }

    renderSold() {
        const { itemPk,
                claimedFromPartyPk,
                claimedFromFbGroupFbId,
                claimedFromOmniParty,
                isMarketItem } = this.props;
        const { words, iclaimed } = this.state;
        const btnProps = {
            disabled: true,
            className: `btn btn-link ${this.btnSize}`,
        };
        /* show as claimed if the current user claimed it and it is not
         * batched, otherwise show as sold */
        const verbed = iclaimed.date && !iclaimed.is_batched
                     ? words.claimed : words.sold;
        return (
            <div className="text-small">
              <button {...btnProps}>{verbed}!</button>
              {this.renderClaimedContent() || (
                  !isMarketItem &&
                  <StrNextButton itemPk={itemPk}
                                 btnSize="btn-xs"
                                 claimedFromPartyPk={claimedFromPartyPk}
                                 claimedFromFbGroupFbId={claimedFromFbGroupFbId}
                                 claimedFromOmniParty={claimedFromOmniParty} />
              )}
            </div>
        );
    }

    renderDisallowAnon() {
        const { words } = this.state;
        const btnProps = {
            disabled: true,
            className: `btn btn-primary ${this.btnSize}`,
        };
        return (
            <div>
              <button {...btnProps}>
                <i className="fa fa-shopping-bag"></i> {words.claim}
              </button>
              <div className="text-small" style={{marginTop: "9px"}}>
                You must be logged in with a verified account to {words.claim.toLowerCase()} with this seller.{' '}
                <a href={`${str_login_url}?next=${window.location.pathname}`}>Log in</a>
              </div>
            </div>
        );
    }

    renderDisallowUnverified() {
        const { words } = this.state;
        const btnProps = {
            disabled: true,
            className: `btn btn-primary ${this.btnSize}`,
        };
        return (
            <div>
              <button {...btnProps}><i className="fa fa-shopping-bag"></i>
                {' '}{words.claim}
              </button>
              <div className="text-small" style={{marginTop: "9px"}}>
                You must <a href="/settings/general/">verify your account</a> to {words.claim.toLowerCase()} with this seller.{' '}
              </div>
            </div>
        );
    }

    renderClaimQuestions() {
        const claimQuestions = this.state.claimQuestions.map(claimQuestion => {
            return (
                <div key={claimQuestion.pk} className="form-group">
                  <label htmlFor="claim-question"
                         style={{
                             width: '80vw',
                             maxWidth: '600px',
                         }}>
                    {claimQuestion.questiontext}
                    {claimQuestion.is_required && '*'}
                  </label>
                  <input type="text"
                         className="form-control"
                         maxLength="200"
                         placeholder="Response"
                         value={claimQuestion.response || ''}
                         onChange={this.updateClaimQuestionResponse.bind(
                             this,
                             claimQuestion.pk)} />
                </div>
            );
        });
        const style = claimQuestions.length === 0 ? {} : {marginTop: "15px"};
        return (
            <div className="row" style={style}>
              <div className="col-md-12">
                {claimQuestions}
              </div>
            </div>
        );
    }

    renderClaimQuestionModal() {
        const { iclaimed, claiming, modalMessage,
                errorMessage, words } = this.state;
        const claimWords = iclaimed.date ? words.claim_another : words.claim;
        return (
            <ModalContainer onClose={this.onClaimQuestionModalClose}
                            isOpen={true}
                            style={{
                                maxWidth: '1000px',
                                zIndex: 1001,
                            }}>
              <h2>{words.claim} Item</h2>
              {this.renderClaimQuestions()}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <button className="btn btn-primary"
                            style={{marginBottom: '10px'}}
                            onClick={(e) => this.onModalClaimClick(e)}
                            disabled={claiming}>
                      {claimWords}
                      {' '}
                      {claiming && <LoadingSpinner />}
                    </button>
                    {modalMessage &&
                     <div className="alert alert-danger" role="alert">
                       {modalMessage}
                     </div>
                    }
                    {errorMessage &&
                     <div className="alert alert-danger text-small">{errorMessage}</div>
                    }
                  </div>
                </div>
              </div>
            </ModalContainer>
        );
    }

    renderAnonClaimModal() {
        const { iclaimed, customerName, customerEmail,
                customerAddress1, customerAddress2, customerCity,
                customerState, customerZipp, customerPhone, claiming,
                modalMessage, errorMessage, words } = this.state;
        const claimWords = iclaimed.date ? words.claim_another : words.claim;
        return (
            <ModalContainer
                onClose={this.onAnonClaimModalClose}
                isOpen={true}
                style={{
                    maxWidth: '1000px',
                    zIndex: 1001,
                }}>
              <h2>{words.claim} Item</h2>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="anon-name">Name</label>
                    <input type="text"
                           className="form-control"
                           placeholder="Name"
                           value={customerName}
                           onChange={this.updateStateThing.bind(this, 'customerName')} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="anon-email">Email</label>
                    <input type="text"
                           className="form-control"
                           placeholder="Email"
                           value={customerEmail}
                           onChange={this.updateStateThing.bind(this, 'customerEmail')} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="anon-address1">Address</label>
                    <input type="text"
                           className="form-control"
                           placeholder="Address 1"
                           value={customerAddress1}
                           onChange={this.updateStateThing.bind(this, 'customerAddress1')} />
                  </div>
                  <div className="form-group">
                    <input type="text"
                           className="form-control"
                           placeholder="Address 2"
                           value={customerAddress2}
                           onChange={this.updateStateThing.bind(this, 'customerAddress2')} />
                  </div>
                </div>
              </div>

              <div className="row" style={{marginBottom: "15px"}}>
                <div className="col-md-4">
                  <input type="text"
                         className="form-control"
                         placeholder="City"
                         value={customerCity}
                         onChange={this.updateStateThing.bind(this, 'customerCity')} />
                </div>
                <div className="col-md-2">
                  <input type="text"
                         className="form-control"
                         placeholder="State or Province"
                         value={customerState}
                         onChange={this.updateStateThing.bind(this, 'customerState')} />
                </div>
                <div className="col-md-3">
                  <input type="text"
                         className="form-control"
                         placeholder="Zip or Postal Code"
                         value={customerZipp}
                         onChange={this.updateStateThing.bind(this, 'customerZipp')} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <input type="text"
                         className="form-control"
                         placeholder="Phone"
                         value={customerPhone}
                         onChange={this.updateStateThing.bind(this, 'customerPhone')} />
                </div>
              </div>
              {this.renderClaimQuestions()}
              <div className="row" style={{marginTop: "15px"}}>
                <div className="col-md-12">
                  <small>
                    For quicker shopping,
                    {' '}
                    <a href={`${str_login_url}?next=${window.location.pathname}`}>log in</a>
                    {' '}or{' '}
                    <a href='/accounts/register/'>create an account</a>.
                  </small>
                  <div className="form-group">
                    <button className="btn btn-primary"
                            style={{marginBottom: '10px'}}
                            onClick={(e) => this.onModalClaimClick(e)}
                            disabled={claiming}>
                      {claimWords}
                      {' '}
                      {claiming && <LoadingSpinner />}
                    </button>
                    {modalMessage &&
                     <div className="alert alert-danger" role="alert">
                       {modalMessage}
                     </div>
                    }
                    {errorMessage &&
                     <div className="alert alert-danger text-small">{errorMessage}</div>
                    }
                  </div>
                </div>
              </div>
            </ModalContainer>
        );
    }

    renderAddressUpdateModal() {
        return (
            <ModalContainer onClose={this.onAddressUpdateModalClose} isOpen={true} style={{zIndex: 1001}}>
              <AddressUpdate onSuccess={this.onAddressUpdateModalClose} />
            </ModalContainer>
        );
    }

    checkAndFetchItemInfo() {
        // this checks for the bare minimum info we need in order to use the
        // ClaimButton, the bare minimum in order to render is actually less, see
        // neededRenderFields in render() for that list
        const { quantity, disableAnonClaims, iclaimed, words,
                variantData } = this.props;
        const neededFields = [
            quantity,
            disableAnonClaims,
            iclaimed,
            words,
            variantData,
        ];
        if (!neededFields.every((f) => typeof f !== 'undefined')) {
            this.fetch();
        } else {
            this.setState({loading: false});
        }
    }

    componentDidMount() {
        this.fetchController = new AbortController();
        this.checkAndFetchItemInfo();
        this.fetchUniUser();

        /*
         * The reason we need this listener is because some pages
         * (e.g. party pages) have multiple claim buttons on them, so we
         * have to refresh the user data across all instances of the
         * ClaimButton component for users that plop into a party page and
         * start claiming without refreshing their page.
         */
        window.addEventListener(USER_INFO_CHANGE_EVENT_NAME, () => {
            this.fetchUniUser(true);
            const anonData = anonInfoGet();
            if (anonData) {
                this.setState(prevState => ({
                    ...prevState,
                    ...anonData,
                }));
            }
        });
        window.addEventListener(this.TRIGGER_FETCH_KEY, this.fetch);
    }

    componentDidUpdate(prevProps, prevState) {
        // If we have a new pk but no old pk then we just finished loading
        const newIPk = this.state.item && this.state.item.pk;
        const oldIPK = prevState.item && prevState.item.pk;
        if (newIPk && !oldIPK && this.props.onLoaded)
            this.props.onLoaded();
    }

    componentWillUnmount() {
        this.fetchController.abort();
        this.aborted = true;
    }

    async fetch() {
        const url = `/api/v2/public_items/${this.props.itemPk}/`;
        const item = await fetchJSON(url, this.fetchController.signal);
        if (item === null)
            return;
        this.setState({
            item: item,
            quantity: item.quantity,
            disableAnonClaims: item.disable_anon_claims,
            iclaimed: item.iclaimed,
            // don't replace the words if we have them already, they
            // could be from a source other than the item
            words: this.props.words || item.words,
            loading: false,
            variantData: item.itemvariant_data,
            selectedVariantPk: item.itemvariant_data
                            && item.itemvariant_data.length > 0
                            && item.itemvariant_data.find(v => v.quantity)
                            && item.itemvariant_data.find(v => v.quantity).pk,
        });
    }

    async fetchUniUser(nocache = false) {
        if (StrUserInfo.uuid === 0)
            return;
        const uniuser = await getUniUser(StrUserInfo.uuid, this.fetchController.signal, nocache);
        if (uniuser === null || this.aborted)
            return;
        this.setState({uniuser});
    }

    async onClaimClick(event) {
        const url = `/api/v2/public_items/${this.props.itemPk}/claim_questions/`;
        const claimQuestions = await fetchJSON(url);
        if (str_user_id === -1) {
            this.setState({
                showAnonClaimModal: true,
                claimQuestions: claimQuestions,
            });
        } else {
            if (claimQuestions.length === 0) {
                this.claim(event);
            } else {
                this.setState({
                    showClaimQuestionModal: true,
                    claimQuestions: claimQuestions,
                });
            }
        }
    }

    onAnonClaimModalClose() {
        this.setState({showAnonClaimModal: false});
    }

    onClaimQuestionModalClose() {
        this.setState({showClaimQuestionModal: false});
    }

    onAddressUpdateModalClose() {
        this.setState({showAddressUpdateModal: false});
    }

    onUpdateAddressLinkClick(e) {
        e.preventDefault();
        this.setState({showAddressUpdateModal: true});
    }

    onVariantPick(variant) {
        this.setState({
            selectedVariantPk: variant.pk,
            quantity: variant.quantity,
        });
        if (this.props.onVariantChange)
            this.props.onVariantChange(variant);
    }

    updateStateThing(thing, event) {
        this.setState({[thing]: event.target.value});
    }

    updateClaimQuestionResponse(pk, event) {
        if (!event.target)
            return;
        const value = event.target.value;
        this.setState(prevState => {
            const index = prevState.claimQuestions
                                   .findIndex(cq => cq.pk === pk);
            return update(prevState, {
                claimQuestions: {
                    [index]: {
                        response: {$set: value},
                    },
                },
            });
        });
    }

    onModalClaimClick(event) {
        event.preventDefault();
        if (!this.state.uniuser && (this.state.customerName === ''
            || this.state.customerAddress1 === ''
            || this.state.customerCity === ''
            || this.state.customerState === ''
            || this.state.customerZipp === ''
            || this.state.customerPhone === ''
            || !isEmail(this.state.customerEmail))) {
            this.setState({modalMessage: "Please enter your name, address, phone, and a valid email address."});
        } else {
            this.setState({modalMessage: ""});
            const customerInfo = {
                customerEmail: this.state.customerEmail,
                customerName: this.state.customerName,
                customerAddress1: this.state.customerAddress1,
                customerAddress2: this.state.customerAddress2,
                customerCity: this.state.customerCity,
                customerState: this.state.customerState,
                customerZipp: this.state.customerZipp,
                customerPhone: this.state.customerPhone,
            };
            if (this.state.claimQuestions.some(cq => cq.is_required && !cq.response)) {
                this.setState({
                    modalMessage: "Questions marked with * are required, please provide responses.",
                });
                return;
            }
            anonInfoSave(customerInfo);
            this.claim(event, customerInfo);
        }
    }

    claim(event, customerInfo=null) {
        const { words, selectedVariantPk } = this.state;
        this.setState({claiming: true});
        const url = '/api/v2/claimrecord/';
        const data = {
            'item_id': this.props.itemPk,
            'dropship_item_id': this.props.dsItemPk,
            'claimed_from': this.props.claimedFromPartyPk,
            'claimed_from_album': this.props.claimedFromPartyAlbumPk,
            'claimed_from_fb_group_fbid': this.props.claimedFromFbGroupFbId,
            'claimed_from_omniparty': this.props.claimedFromOmniParty,
            'claim_questions': JSON.stringify(this.state.claimQuestions),
            'is_promoted': this.props.isPromoted,
        };
        if (selectedVariantPk) {
            data['itemvariant_id'] = selectedVariantPk;
        }

        if (customerInfo) {
            data['customer_email'] = customerInfo.customerEmail;
            data['customer_name'] = customerInfo.customerName;
            data['customer_address1'] = customerInfo.customerAddress1;
            data['customer_address2'] = customerInfo.customerAddress2;
            data['customer_city'] = customerInfo.customerCity;
            data['customer_state'] = customerInfo.customerState;
            data['customer_zipp'] = customerInfo.customerZipp;
            data['customer_phone'] = customerInfo.customerPhone;
        } else if (str_user_id === -1) {
            throw "Programming errorMessage: ClaimButton.claim() called by " +
            "anon but without customerInfo. Please report this incident to " +
            "customer support.";
        }

        $.post(url, data, (rsp, status, xhr) => {
            if (xhr.status === 205) {
                /* stale data so refresh and check if the item has
                 * already been claimed/is no longer available */
                this.fetch().then(() => {
                    if (this.state.item.quantity <= 0) {
                        this.setState({errorMessage: `${words.claimed} by someone else, click Next to get in line for this item if it becomes available again`});
                    }
                });
                return;
            }
            if (xhr.status !== 201) {
                alert(`Failed to ${words.claim.toLowerCase()}, please try again. If the problem persists please contact support.`);
                return;
            }
            if ('ga' in window) {
                var tracker = `partyTracker${this.props.claimedFromPartyPk}.send`;
                ga(tracker, {
                    hitType: 'event',
                    eventCategory: 'Claim Item',
                    eventAction: 'Claimed Item',
                    eventValue: data['item_id'],
                });
            }
            if ('fbq' in window)
                fbq('trackCustom', 'Claim Item', {item_id: data['item_id']});
            toast.success(`${words.claimed} 1 item`);
            window.dispatchEvent(new Event(this.TRIGGER_FETCH_KEY));
            this.setState({
                claiming: false,
                justDidClaim: true,
                showClaimQuestionModal: false,
                showAnonClaimModal: false,
                errorMessage: null,
            });
            $(document).trigger('sync-carts');
        }).fail((rsp) => {
            this.setState({
                errorMessage: `${words.claim} failed: ${JSON.stringify(rsp.responseJSON)} ` +
                              'Please try again later. If the problem persists ' +
                              'please contact customer support.',
                claiming: false,
            });
        });
    }
}

ClaimButton.propTypes = {
    itemPk: PropTypes.number.isRequired,
    dsItemPk: PropTypes.number,
    btnSize: PropTypes.string,
    claimedFromPartyPk: PropTypes.number,
    claimedFromPartyAlbumPk: PropTypes.number,
    claimedFromFbGroupFbId: PropTypes.number,
    claimedFromOmniParty: PropTypes.bool,
    disableAnonClaims: PropTypes.bool,
    quantity: PropTypes.number,
    iclaimed: PropTypes.object,
    words: PropTypes.object,
    variantData: PropTypes.array,
    isMarketItem: PropTypes.bool,
    // Called when the button has finished loading and rendering.
    // Useful for measuring height.
    onLoaded: PropTypes.func,
    minHeight: PropTypes.number,
    isPromoted: PropTypes.bool,
    onVariantChange: PropTypes.func,
};

export default ClaimButton;
